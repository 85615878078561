<template>
    <div>
        <b-card class="my-1 d-flex align-center" :title="$t('material.delivery_notes.add_delivery_note')">
            <custom-loader :show="showLoader">

                <v-select v-if="editing" v-model="selected" @input="setSelection" appendToBody class="floating-filter w-100" :options="versions" :clearable="false" :searchable="false" label="id" :reduce="item => item">
                    <template #selected-option="{id,version_timestamp}">
                        <span  class="textCutSelect">
                            {{$t('version.current')}} - {{0 > getIndexById(id)  ? versions.length === 0 ? versions.length : versions.length-1  : versions.length - getIndexById(id)-1}}
                            ({{ $dayjs(version_timestamp).format('DD.MM.YYYY HH:mm:ss') }})
                        </span>
                    </template>
                    <template #option="{id,version_timestamp}">
                        <span  class="textCutSelect">
                            {{$t('version.version')}} - {{versions.length - getIndexById(id) -1}}
                            ({{ $dayjs(version_timestamp).format('DD.MM.YYYY HH:mm:ss') }})
                        </span>
                    </template>
                </v-select>


                <div v-if="!showLoader" class="mt-5">
                    <validation-observer ref="validationDeliveryNote">

                        <div class="mb-1 w-100 d-flex justify-content-end mt-3" v-if="deliveryNote && editing">
                            <b-button style="margin-right: 5px;" variant="primary" @click="viewReportModal">
                                {{ $t('general.view_printout') }}
                            </b-button>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 pr-md-0">
                                <div class="data-border">
                                    <div class="border-text bgCard">
                                        {{ $t('material.delivery_notes.delivery_note_data') }}
                                    </div>

                                    <!-- CONTENT -->
                                    <div class="row p-2">
                                        <div class="col-12 col-md-6">
                                            <b-form-group :disabled="true" :label="$t('material.delivery_notes.code')">
                                                <b-form-input v-model="addObject.code" />
                                            </b-form-group>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <b-form-group :label="$t('material.delivery_notes.pick_up_date')">
                                                <div v-if="addObject && deliveryNote && editing && addObject.timestamp !== deliveryNote.timestamp" class="pb-1" style="text-decoration: line-through">
                                                    <input class="form-control"
                                                           :value="formatDate(deliveryNote.timestamp)"
                                                           :disabled="true"
                                                    />
                                                </div>

                                                <validation-provider #default="{ errors }" :name="$t('material.delivery_notes.pick_up_date')" rules="required">
                                                    <date-picker :locale="currentLocale" :first-day-of-week="2" v-model="addObject.timestamp" :masks="{ input: 'YYYY-MM-DD', L: 'YYYY-MM-DD'}" :model-config="modelConfig" is24hr hide-time-header mode="date" :is-required="true" @input="updateTemporaryFileDeliveryNote">
                                                        <template v-slot="{ inputValue, inputEvents }">
                                                            <input class="form-control"
                                                                   :value="formatDate(inputValue)"
                                                                   v-on="inputEvents"
                                                            />
                                                        </template>
                                                    </date-picker>
                                                    <small class="text-danger">{{errors[0]}}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <b-form-group :label="$t('material.delivery_notes.number_of_delivery_note')">
                                                <div  v-if="addObject && deliveryNote && editing && addObject.delivery_note_number && deliveryNote.delivery_note_number && addObject.delivery_note_number !== deliveryNote.delivery_note_number" class="pb-1" style="text-decoration: line-through">
                                                    <b-form-input v-model="deliveryNote.delivery_note_number" :disabled="true"/>
                                                </div>
                                                <validation-provider #default="{ errors }" :name="$t('material.delivery_notes.number_of_delivery_note')">
                                                    <b-form-input v-model="addObject.delivery_note_number" @input="updateTemporaryFileDeliveryNote" @change="updateTemporaryFileDeliveryNote"/>
                                                    <small class="text-danger">{{errors[0]}}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <b-form-group :label="$t('material.delivery_notes.accepted_by')" v-if="userData">
                                                <validation-provider #default="{ errors }" :name="$t('material.delivery_notes.accepted_by')" rules="required">
                                                    <b-form-input disabled v-model="userData.name"/>
                                                    <small class="text-danger">{{errors[0]}}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <b-form-group :label="$t('general.tax_rate')">
                                                <div v-if="deliveryNote && editing && customVat !== deliveryNote.vat/100" class="pb-1">
                                                    <b-input-group>
                                                        <b-form-input v-model="formattedVat" :disabled="true" style="text-decoration: line-through"/>
                                                    </b-input-group>
                                                </div>

                                                <validation-provider #default="{ errors }" :name="$t('keys.vat.vat')" :rules="vat_rules">
                                                    <b-input-group>
                                                        <b-form-input :disabled="!useCustomVat" v-model="customVat" @change="changeCustomVat"/>
                                                        <b-input-group-append is-text>
                                                            <span class="mr-1">
                                                                %
                                                            </span>
                                                            <b-form-checkbox plain v-model="useCustomVat" @change="changeUseCustomVat"/>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <small class="text-danger">{{errors[0]}}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </div>

                                        <div class="col-12 col-md-6">
                                            <b-form-group :label="$t('general.discount')">
                                                <div v-if="deliveryNote && editing && discountNotConverted !== deliveryNote.discount/100" class="pb-1">
                                                    <b-input-group>
                                                        <b-form-input v-model="formattedDiscount" :disabled="true" style="text-decoration: line-through"/>
                                                    </b-input-group>
                                                </div>

                                                <validation-provider #default="{ errors }" :name="$t('general.discount')" rules="required|decimalCustom:2|mustBePositive:true">
                                                    <b-input-group>
                                                        <b-form-input :disabled="!useDiscount" @change="changeDiscount" v-model="discountNotConverted" />
                                                        <b-input-group-append is-text>
                                                            <span class="mr-1">
                                                                %
                                                            </span>
                                                            <b-form-checkbox plain v-model="useDiscount" @change="changeUseDiscount"/>
                                                        </b-input-group-append>
                                                    </b-input-group>
                                                    <small class="text-danger">{{errors[0]}}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </div>

                                        <div class="col-12">
                                            <b-form-group :label="$t('general.warehouse')">
                                                <div v-if="addObject && deliveryNote && editing && warehouseData.id && deliveryNote.warehouse_id && warehouseData.id !== deliveryNote.warehouse_id" class="pb-1" style="text-decoration: line-through">
                                                    <v-select v-model="deliveryNote.warehouse_id" :disabled="true" appendToBody label="name" :options="warehouses" :clearable="false" :reduce="item => item.id"/>
                                                </div>

                                                <validation-provider #default="{ errors }" :name="$t('general.warehouse')" rules="required">
                                                    <v-select v-model="warehouseData" ref="warehouseSelect" appendToBody label="name" :options="warehouses" @option:selected="reassignAllProduct"/>
                                                    <small class="text-danger">{{errors[0]}}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </div>
                                    </div>

                                    <div v-if="deliveryNote && editing && differenceInDeliveryNoteData" class="d-flex p-1 justify-content-end">
                                        <b-button variant="warning" @click="revertLeftSquareData">{{ $t('general.revert') }}</b-button>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 col-md-6 pl-md-0 mt-2 mt-md-0">
                                <div class="data-border">
                                    <div class="border-text bgCard">
                                        {{ $t('material.delivery_notes.supplier_data') }}
                                    </div>

                                    <!-- CONTENT -->
                                    <div style="padding-bottom: 2px">
                                        <div class="row p-2">
                                            <div class="col-12">
                                                <b-form-group :label="$t('material.suppliers.supplier')">
                                                    <div v-if="addObject && deliveryNote && editing && supplierData && supplierData.id && deliveryNote.supplier_id && supplierData.id !== deliveryNote.supplier_id" class="pb-1" style="text-decoration: line-through">
                                                        <v-select v-model="deliveryNote.supplier_id" :disabled="true" appendToBody label="name" :options="suppliers" :clearable="false" :reduce="item => item.id"/>
                                                    </div>

                                                    <validation-provider #default="{ errors }" :name="$t('material.suppliers.supplier')" rules="required">
                                                        <v-select v-model="supplierData" appendToBody label="name" :options="suppliers" @input="updateTemporaryFileDeliveryNote"/>
                                                        <small class="text-danger">{{errors[0]}}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </div>
                                            <div class="col-12">
                                                <b-form-group :disabled="true" :label="$t('form_data.address')">
                                                    <b-form-input v-if="supplierData" v-model="supplierData.address"/>
                                                    <b-form-input v-else />
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <b-form-group :disabled="true" :label="$t('form_data.contact_person')">
                                                    <b-form-input v-if="supplierData && supplierData.contacts && supplierData.contacts.length > 0" v-model="supplierData.contacts[0].name" />
                                                    <b-form-input v-else />
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <b-form-group :disabled="true" :label="$t('form_data.phone')">
                                                    <b-form-input v-if="supplierData && supplierData.contacts && supplierData.contacts.length > 0" v-model="supplierData.contacts[0].phone" />
                                                    <b-form-input v-else />
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-6">
                                                <b-form-group :disabled="true" :label="$t('form_data.country')">
                                                    <b-form-input v-if="supplierData" v-model="supplierData.country"/>
                                                    <b-form-input v-else />
                                                </b-form-group>
                                            </div>
                                            <div class="col-12 col-md-6" style="margin-top: 27.4px">
                                                <b-button variant="primary" style="height: 38px" block @click="addSupplier">
                                                    {{ $t('material.suppliers.add_supplier') }}</b-button>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="deliveryNote && editing && differenceInSupplierData" class="d-flex p-1 justify-content-end">
                                        <b-button variant="warning" @click="revertRightSquareData">{{ $t('general.revert') }}</b-button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="my-2 d-flex justify-content-between align-items-center">
                            <div>
                                <h4 class="card-title my-auto">{{ $t('general.ingredients') }}</h4>
                            </div>
                            <div class="d-flex justify-content-end">
                                <div v-if="deliveryNote && editing && !compareItemsArray(addObject.items, deliveryNote.items)" class="pr-2">
                                    <b-button variant="warning" @click="revertTableChanges">{{ $t('general.revert') }}</b-button>
                                </div>
                                <div>
                                    <b-button variant="primary" :disabled="!supplierData" @click="openModalAddProduct">{{ $t('general.add_ingredient') }}</b-button>
                                </div>
                            </div>
                        </div>
                        <div class="mt-2 mb-3">
                            <Table ref="itemsTable" :columnDefs="columnDefs" :rowData="joinedTable" :decimals="addObject.decimals" @deleteRow="removeItemFromTable" @editRow="editItem"/>
                        </div>

                        <div class="row">
                            <div class="col-12 col-md-6 pr-md-0">
                                <div class="data-border">
                                    <div class="border-text bgCard">
                                        {{ $t('material.delivery_notes.total_of_delivery_note') }}
                                    </div>

                                    <!-- CONTENT -->
                                    <div class="row p-2">
                                        <div class="col-6 text-price">
                                            {{ $t('material.total_price_without_vat') }}:
                                        </div>
                                        <div class="col-6 text-price-bold text-right">
                                            {{$numberRounding(deliveryNotePrice.price_without_vat)}}€
                                        </div>
                                        <!-- ALTERNATE TEXT - WITHOUT VAT -->
                                        <div v-if="deliveryNote && editing && $numberRounding(deliveryNotePrice.price_without_vat) !== $numberRounding(this.deliveryNote.price_with_discount)" class="col-12 text-right" style="color: #e74c3c; text-decoration: line-through;">
                                            {{$numberRounding(this.deliveryNote.price_with_discount)}}€
                                        </div>

                                        <div class="col-6 text-price mt-1">
                                            {{ $t('material.discount_value') }}:
                                        </div>
                                        <div class="col-6 text-price-bold text-right mt-1">
                                            {{$numberRounding(deliveryNotePrice.discount_value)}}€
                                        </div>
                                        <!-- ALTERNATE TEXT - ORIGINAL -->
                                        <div v-if="deliveryNote && editing && $numberRounding(deliveryNotePrice.discount_value) !== $numberRounding(this.discount_value)" class="col-12 text-right" style="color: #e74c3c; text-decoration: line-through;">
                                            {{$numberRounding(this.discount_value)}}€
                                        </div>

                                        <div class="col-12">
                                            <div v-for="(vatValue, rate) in deliveryNotePrice.vat_by_rate" :key="rate" class="row mt-1">
                                                <div class="col-6 text-price">
                                                    {{ $t('material.vat') }} {{ rate / 100 }}%:
                                                </div>
                                                <div class="col-6 text-price-bold text-right">
                                                    {{$numberRounding(vatValue)}}€
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-6 text-price mt-1">
                                            {{ $t('material.vat_total') }}:
                                        </div>
                                        <div class="col-6 text-price-bold text-right mt-1">
                                            {{$numberRounding(deliveryNotePrice.vat_value)}}€
                                        </div>

                                        <div class="col-6 text-price mt-1">
                                            {{ $t('material.total_price') }}:
                                        </div>
                                        <div class="col-6 text-price-bold text-right mt-1">
                                            {{$numberRounding(deliveryNotePrice.total)}}€
                                        </div>
                                        <!-- ALTERNATE TEXT - TOTAL -->
                                        <div v-if="deliveryNote && editing && $numberRounding(deliveryNotePrice.total) !== $numberRounding(deliveryNote.price_with_discount_and_vat)" class="col-12 text-right" style="color: #e74c3c; text-decoration: line-through;">
                                            {{$numberRounding(deliveryNote.price_with_discount_and_vat)}}€
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-full pl-md-0 mt-2 d-flex justify-content-between align-items-end" style="margin-left: 5px;">
                            <div class="d-flex">
                                <div class="pr-2">
                                    <b-button variant="danger" @click="deleteDeliveryNote">
                                        <span class="align-middle">{{ $t('general.delete') }}</span>
                                    </b-button>
                                </div>
                                <div v-if="deliveryNote && editing  && (differenceInDeliveryNoteData || differenceInSupplierData || !compareItemsArray(addObject.items, deliveryNote.items))" class="pr-2">
                                    <b-button variant="warning" @click="discardChangesDeliveryNote">
                                        <span class="align-middle">{{ $t('general.discard') }}</span>
                                    </b-button>
                                </div>
                            </div>
                            <div>
                                <b-button variant="success" @click="confirmDeliveryNote(true)" :disabled="!allowConfirm">
                                    <span v-if="deliveryNote && editing" class="align-middle">{{ $t('general.accept') }}</span>
                                    <span v-else class="align-middle">{{ $t('material.delivery_notes.finish_delivery_note') }}</span>
                                </b-button>
                            </div>
                        </div>

                    </validation-observer>
                </div>
            </custom-loader>
        </b-card>

        <report-preview-modal ref="deliveryNoteReportModal" />
        <add-product-to-delivery-note ref="deliveryNoteAddProduct" :supplier="supplierData" :selectedWarehouse="warehouseData" :useCustomVat="useCustomVat" :customVat="customVat"  :warehouses="warehouses" v-on:addItem="addItem"></add-product-to-delivery-note>
        <add-supplier ref="supplierModal" :vats="vats" v-on:itemAdded="loadSuppliers"/>
    </div>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import vSelect from 'vue-select'
    import {
        BButton,
        BCard,
        BFormCheckbox,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend
    } from 'bootstrap-vue'
    import {DatePicker} from 'v-calendar'
    import AddProductToDeliveryNote from './AddProductToDeliveryNote.vue'
    import AddSupplier from '@/views/Supplier/AddSupplier.vue'
    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {required} from '@validations'
    import * as Sentry from '@sentry/vue'
    import ReportPreviewModal from '@/views/components/ReportPreviewModal.vue'
    import CustomLoader from '@/components/CustomLoader.vue'
    import {getMoneyTypes, getPieceTypes, getVolumeTypes, getWeightTypes} from '@/libs/constants/ingredients.js'
    import {getPackaging, getType} from '@/libs/material'

    export default {
        components: {
            CustomLoader,
            ReportPreviewModal,
            BCard,
            BFormInput,
            BFormGroup,
            BButton,
            Table,
            vSelect,
            DatePicker,
            AddProductToDeliveryNote,
            ValidationProvider,
            ValidationObserver,
            BInputGroup,
            AddSupplier,
            BFormCheckbox,
            BInputGroupAppend
        },
        data() {
            return {
                apiUrl: '',
                fileName: '',
                editing: false,
                discountNotConverted: 0,
                deliveryNote: null,
                useDiscount: false,
                deliveryNoteId: null,
                confirmed: true,
                useCustomVat: false,
                customVat: 0,
                required,
                modelConfig: {
                    type: 'string',
                    mask: 'iso'
                },
                currentUser: null,
                showLoader: true,
                deliveryNoteUser: '',
                addObject:{
                    supplier_id: '',
                    code: null,
                    timestamp: new Date(),
                    warehouse_id: '',
                    delivery_note_number: '',
                    user_id: '',
                    discount: 0,
                    invoice_number: null,
                    items: [],
                    decimals: 2
                },
                supplierData: null,
                warehouseData: null,
                suppliers: [],
                warehouses: [],
                vats: [],
                deliveryNotePrice: {
                    total: 0,
                    price_without_vat: 0,
                    discount_value: 0,
                    vat_value: 0,
                    vat_by_rate: {}
                },
                userData: null,
                editingItemIndex: -1,
                masks: {
                    input: 'D. M. YYYY'
                },
                price_without_vat: 0,
                discount_value: 0,
                versions:[],
                selected:{id:null},
                finished:false,
                allowConfirm:true

            }
        },
        computed: {
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            },
            columnDefs() {
                return [
                    { headerName: this.$t('table_fields.name'),  editable: false, field: 'ingredient_id', filter: true, cellRenderer: (params) => this.getFieldIngredientName(params.data)},
                    { headerName: this.$t('table_fields.quantity'),  editable: false, field: 'quantity', filter: true, cellRenderer: (params) => this.getFieldQuantity(params.data), filterParams: {type: 'number'}},
                    { headerName: this.$t('table_fields.packaging'),  editable: false, field: 'packaging', filter: true, filterParams: {textCustomComparator: (filter, value, filterText) => this.$amountFilter(filter, value * 10000, filterText)}, cellRenderer: (params) => this.getFieldPackaging(params.data)},
                    { headerName: this.$t('table_fields.warehouse'),  editable: false, floatingFilterComponentParams: () => { return  {type: 'select', values: this.get_warehouses} }, field: 'warehouse_id', filter: true, cellRenderer: (params) => this.getWarehouseName(params.value)},
                    { headerName: this.$t('table_fields.price_per_unit'),  editable: false, field: 'price_per_unit', filter: true, filterParams: {textCustomComparator: this.$amountFilter}, cellRenderer: (params) => this.getFieldPricePerUnit(params.data)},
                    { headerName: this.$t('table_fields.discount'),  editable: false, field: 'discount_value_field', filter: true, filterParams: {textCustomComparator: this.$amountFilter}, cellRenderer: (params) => this.getDiscountValue(params.data)},
                    { headerName: this.$t('table_fields.tax_rate'),  editable: false, field: 'vat', filter: true, cellRenderer:'DisplayPercent'},
                    { headerName: this.$t('table_fields.net_price'),  editable: false, field: 'price_with_discount', filter: true, filterParams: {textCustomComparator: this.$amountFilter},  cellRenderer:'DisplayPrice', cellRendererParams: () => { return { decimals: this.deliveryNote.decimals } }},
                    { headerName: this.$t('table_fields.vat'),  editable: false, field: 'vv', filter: true, filterParams: {textCustomComparator: this.$amountFilter}, cellRenderer: (params) => this.getVatValue(params.data)},
                    { headerName: this.$t('table_fields.total'),  editable: false, field: 'price_with_discount_and_vat', filter: true, filterParams: {textCustomComparator: this.$amountFilter}, cellRenderer:'DisplayPrice', cellRendererParams: () => { return { decimals: this.deliveryNote.decimals } }},
                    { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', cellRendererParams: {removeObject: true}, minWidth: 150}
                ]
            },
            columns() {
                return [
                    { headerName: this.$t('table_fields.name'),  editable: false, field: 'ingredient_id', filter: true, cellRenderer: (params) => this.getIngredientName(params.value)},
                    { headerName: this.$t('table_fields.quantity'),  editable: false, field: 'quantity', filter: true},
                    { headerName: this.$t('table_fields.packaging'),  editable: false, field: 'packaging', filter: true, cellRenderer: (params) => this.getPackaging(params.data)},
                    { headerName: this.$t('table_fields.warehouse'),  editable: false, floatingFilterComponentParams: () => { return  {type: 'select', values: this.get_warehouses} }, field: 'warehouse_id', filter: true, cellRenderer: (params) => this.getWarehouseName(params.value)},
                    { headerName: this.$t('table_fields.price_per_unit'),  editable: false, field: 'price_per_unit', filter: true, floatingFilterComponentParams: {type: 'number'}, cellRenderer:'DisplayPrice'},
                    { headerName: this.$t('table_fields.discount'),  editable: false, field: 'discount_value_field', filter: true, cellRenderer: (params) => this.getDiscountValue(params.data)},
                    { headerName: this.$t('table_fields.tax_rate'),  editable: false, field: 'vat', filter: true, cellRenderer:'DisplayPercent'},
                    { headerName: this.$t('table_fields.net_price'),  editable: false, field: 'price_with_discount', filter: true, floatingFilterComponentParams: {type: 'number'}, cellRenderer:'DisplayPrice'},
                    { headerName: this.$t('table_fields.vat'),  editable: false, field: 'vv', filter: true, cellRenderer:(params) => this.getVatValue(params.data)},
                    { headerName: this.$t('table_fields.total'),  editable: false, field: 'price_with_discount_and_vat', filter: true, floatingFilterComponentParams: {type: 'number'}, cellRenderer:'DisplayPrice'}
                ]
            },
            differenceInDeliveryNoteData() {
                if (!this.deliveryNote || !this.addObject) {
                    return false
                }

                return !(this.deliveryNote.timestamp === this.addObject.timestamp &&
                    this.deliveryNote.delivery_note_number === this.addObject.delivery_note_number &&
                    this.deliveryNote.discount === this.addObject.discount &&
                    this.deliveryNote.vat === this.addObject.vat &&
                    this.deliveryNote.warehouse_id === this.warehouseData.id)
            },
            differenceInSupplierData() {
                return this.deliveryNote.supplier_id !== this.supplierData.id

            },
            vat_rules() {
                if (this.useCustomVat) {
                    return 'required|decimalCustom:2|mustBePositive:true'
                }
                return ''
            },
            selectedColumns() {
                if (this.deliveryNote && this.deliveryNote.length > 0) {
                    return this.columns
                } else {
                    return this.columnDefs
                }
            },
            get_warehouses() {
                return this.warehouses
            },
            deliveryNoteItems() {
                if (this.addObject.items !== null) {
                    return this.addObject.items.map((item) => {
                        return {
                            ...item,
                            discount_value_field: item.price - item.price_with_discount,
                            vv: item.price * (item.vat / 10000)
                        }
                    })
                } else {
                    return []
                }
            },
            joinedTable() {
                if (this.deliveryNoteItems && this.deliveryNote && this.deliveryNote.items) {
                    const resultArray = []

                    const deliveryNoteMap = new Map(
                        this.deliveryNoteItems.map(item => [item.unique_id, item])
                    )


                    for (const deliveryNoteTemporaryItem of this.deliveryNote.items) {
                        const deliveryNoteItem = deliveryNoteMap.get(deliveryNoteTemporaryItem.unique_id)

                        if (deliveryNoteItem) {
                            if (this.objectsDiffer(deliveryNoteTemporaryItem, deliveryNoteItem)) {
                                resultArray.push({ ...deliveryNoteItem, temporary_changes: 2 })
                            } else {
                                resultArray.push({ ...deliveryNoteItem, temporary_changes: 3 })
                            }
                        } else {
                            resultArray.push({ ...deliveryNoteTemporaryItem, temporary_changes: 1 })
                        }
                    }

                    if (this.deliveryNoteItems.length !== 0) {
                        for (const deliveryNoteItem of this.deliveryNoteItems) {

                            const existingIndex = resultArray.findIndex(item => item.unique_id === deliveryNoteItem.unique_id)

                            if (existingIndex === -1) {
                                resultArray.push({ ...deliveryNoteItem, temporary_changes: 0 })
                            }
                        }
                    }

                    resultArray.sort((a, b) => a.unique_id.localeCompare(b.unique_id))

                    return resultArray.length ? resultArray : []
                } else {
                    return []
                }
            },
            formattedVat() {
                return `${this.deliveryNote.vat / 100}%`
            },
            formattedDiscount() {
                return `${this.deliveryNote.discount / 100}%`
            },
            currentLocale() {
                return this.$store.getters['user/current_locale']
            }
        },
        methods: {
            getPackaging,
            getQuantityFromPackaging(params) {
                let factor = 1
                const  type = getType(params)
                if (!type) {
                    return
                }

                factor = type.factor

                const packagingInDisplayedUnit = params.packaging / factor
                return `${packagingInDisplayedUnit  }x`
            },
            compareItemsArray(array1, array2) {
                const sortItems = (a, b) => a.ingredient_id.localeCompare(b.ingredient_id)
                array1 = array1.slice().sort(sortItems)
                array2 = array2.slice().sort(sortItems)

                if (array1.length !== array2.length) {
                    return false
                }

                for (let i = 0; i < array1.length; i++) {
                    const item1 = array1[i]
                    const item2 = array2[i]

                    if (
                        item1.unique_id !== item2.unique_id ||
                        item1.ingredient_id !== item2.ingredient_id ||
                        item1.quantity !== item2.quantity ||
                        item1.price_per_unit !== item2.price_per_unit ||
                        item1.price !== item2.price ||
                        item1.price_with_discount !== item2.price_with_discount ||
                        item1.price_with_discount_and_vat !== item2.price_with_discount_and_vat ||
                        item1.discount !== item2.discount ||
                        item1.stock !== item2.stock ||
                        item1.packaging !== item2.packaging ||
                        item1.vat !== item2.vat ||
                        item1.packaging_unit !== item2.packaging_unit ||
                        item1.warehouse_id !== item2.warehouse_id
                    ) {
                        return false
                    }
                }

                return true
            },

            formatDate(inputDate) {
                if (this.selectTime === 1) {
                    return this.dayjs(inputDate).format('dddd, DD.MM.YYYY, HH:mm')
                } else {
                    return this.dayjs(inputDate).format('dddd, DD.MM.YYYY')
                }
            },
            async revertTableChanges() {
                this.addObject.items = JSON.parse(JSON.stringify(this.deliveryNote.items))
                await this.updateTemporaryFileDeliveryNote()
            },
            async viewReportModal() {
                await this.$refs.deliveryNoteReportModal.show(this.fileName, this.apiUrl)
            },
            objectsDiffer(obj1, obj2) {
                return obj1.discount !== obj2.discount ||
                    obj1.ingredient_id !== obj2.ingredient_id || obj1.packaging_unit !== obj2.packaging_unit ||
                    obj1.packaging !== obj2.packaging || obj1.price !== obj2.price || obj1.price_per_unit !== obj2.price_per_unit ||
                    obj1.price_with_discount !== obj2.price_with_discount || obj1.price_with_discount_and_vat !== obj2.price_with_discount_and_vat ||
                    obj1.quantity !== obj2.quantity || obj1.stock !== obj2.stock || obj1.vat !== obj2.vat || obj1.warehouse_id !== obj2.warehouse_id
            },
            getFieldIngredientName(data) {
                return this.checkField(data, this.getIngredientName, 'ingredient_id')
            },
            getFieldPackaging(data) {
                if (data.packaging_unit === 'EUR') {
                    return '1.00 EUR'
                }

                return this.checkField(data, this.getPackaging, 'packaging')
            },
            getFieldQuantity(data) {
                if (data.packaging_unit === 'EUR') {
                    return this.checkField(data,  this.getQuantityFromPackaging, 'packaging')
                }

                return this.checkField(data, undefined, 'quantity')
            },
            getFieldTotal(data) {
                return this.checkField(data, undefined, 'price_with_discount_and_vat')
            },
            getFieldPricePerUnit(data) {
                return this.checkField(data, undefined, 'price_per_unit')
            },
            checkField(data, fn, fieldName) {
                let show = fn ? fn(data[fieldName]) : data[fieldName]
                if (fieldName === 'packaging') {
                    show = fn(data)
                } else if (fieldName === 'price_with_discount_and_vat' || fieldName === 'price_per_unit') {
                    show = `${this.$numberRounding(show)} €`
                }

                if (this.deliveryNote.items.length === 0) { // ustvarjamo nov odpis
                    return `<div>${show}</div>`
                } else if (!this.deliveryNote.items.some(obj => obj.unique_id === data.unique_id)) { // objekta ni v originalnem arrayu
                    return `<div style="color: #2ecc71;">${show}</div>`
                } else if (this.deliveryNote.items.some(obj => obj.unique_id === data.unique_id) && this.deliveryNoteItems.some(obj => obj.unique_id === data.unique_id)) { // objekt obstaja v originalnem arrayju in v temporary
                    const temporaryItem = this.deliveryNoteItems.find(obj => obj.unique_id === data.unique_id)
                    const originalItem = this.deliveryNote.items.find(obj => obj.unique_id === data.unique_id)

                    let original = fn ? fn(originalItem[fieldName]) : originalItem[fieldName]
                    let temporary = fn ? fn(temporaryItem[fieldName]) : temporaryItem[fieldName]

                    if (fieldName === 'packaging') {
                        original = fn(originalItem)
                        temporary = fn(temporaryItem)
                    } else if (fieldName === 'price_with_discount_and_vat' || fieldName === 'price_per_unit') {
                        original = `${this.$numberRounding(original)} €`
                        temporary = `${this.$numberRounding(temporary)} €`
                    }

                    if (originalItem[fieldName] !== temporaryItem[fieldName]) { // vrednosti sta dejansko drugačni
                        return `<div>
                                    <span style="color: #e74c3c; text-decoration: line-through;">${original}</span>
                                    <span style="color: #2ecc71;">${temporary}</span>
                                </span>`
                    } else { // vrednosti sta enaki
                        return `<div>${original}</div>`
                    }
                } else if (this.deliveryNote.items.some(obj => obj.unique_id === data.unique_id) && !this.deliveryNoteItems.some(obj => obj.unique_id === data.unique_id)) { // objekt obstaja v original, ampak ne v temporary
                    const originalItem = this.deliveryNote.items.find(obj => obj.unique_id === data.unique_id)
                    let original = fn ? fn(originalItem[fieldName]) : originalItem[fieldName]
                    if (fieldName === 'packaging') {
                        original = fn(originalItem)
                    } else if (fieldName === 'price_with_discount_and_vat' || fieldName === 'price_per_unit') {
                        original = `${this.$numberRounding(original)} €`
                    }

                    return `<div style="color: #e74c3c; text-decoration: line-through;">${original}</div>`
                }
            },
            getPayloadData() {
                let supplierId = this.$NilObjectId
                let warehouseId = this.$NilObjectId
                let userId = this.$NilObjectId
                if (this.supplierData) {
                    supplierId = this.supplierData.id
                }
                if (this.warehouseData) {
                    warehouseId = this.warehouseData.id
                }
                if (this.userData) {
                    userId = this.userData.id
                }

                return {
                    supplier_id: supplierId,
                    code: this.addObject.code,
                    timestamp: this.addObject.timestamp,
                    use_discount: this.useDiscount,
                    warehouse_id: warehouseId,
                    user_id: userId,
                    discount: Number(this.addObject.discount),
                    delivery_note_number: this.addObject.delivery_note_number,
                    invoice_number: this.addObject.invoice_number,
                    items: this.addObject.items,
                    price: this.addObject.price,
                    price_with_discount: this.addObject.price_with_discount,
                    price_with_discount_and_vat: this.addObject.price_with_discount_and_vat,
                    use_vat: this.useCustomVat,
                    vat: Math.trunc(Number(this.customVat) * 100)
                }
            },
            async updateTemporaryFileDeliveryNote() {
                try {
                    const payload = this.getPayloadData()
                    payload.edited = true
                    payload.field_change = true
                    await this.$http.post(`/api/client/v1/delivery_notes_temporary/${this.$route.params.id}`, payload)

                } catch (err) {
                    Sentry.captureMessage(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async revertRightSquareData() {
                const foundObject = this.suppliers.find(obj => obj.id === this.deliveryNote.supplier_id)
                if (foundObject) {
                    this.supplierData = foundObject
                }
                await this.updateTemporaryFileDeliveryNote()
            },
            async revertLeftSquareData() {


                if (this.deliveryNote.warehouse_id !== this.warehouseData.id) {
                    const foundObject = this.warehouses.find(obj => obj.id === this.addObject.id)
                    if (foundObject) {
                        this.addObject = foundObject
                    }
                    await this.reassignAllProduct()
                }

                this.addObject.timestamp = this.deliveryNote.timestamp
                this.addObject.delivery_note_number = this.deliveryNote.delivery_note_number
                this.customVat = this.deliveryNote.vat
                this.useCustomVat = this.deliveryNote.use_vat
                this.useDiscount = this.deliveryNote.use_discount
                this.discountNotConverted = this.deliveryNote.discount

                this.calculateTotal()

                await this.updateTemporaryFileDeliveryNote()
            },
            generateUniqueId() {
                const idLength = 30

                const timestamp = new Date().getTime().toString(36)

                const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
                let randomString = ''
                for (let i = 0; i < idLength - timestamp.length; i++) {
                    const randomIndex = Math.floor(Math.random() * randomChars.length)
                    randomString += randomChars.charAt(randomIndex)
                }

                return timestamp + randomString
            },
            async deleteDeliveryNote() {

                const confirmDelete = await this.$confirmDialog(this.$t('material.delivery_notes.confirm.delete_delivery_note'))
                if (!confirmDelete) return

                try {


                    await this.$http.delete(`/api/client/v1/delivery_notes/${this.$route.params.id}`)

                    this.$printSuccess(this.$t('print.success.delete'))

                    await this.$router.push({name: 'delivery_notes'})
                } catch (err) {
                    Sentry.captureMessage(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async discardChangesDeliveryNote() {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_temporary_msg'))
                    if (!confirmDelete) return

                    await this.$http.delete(`/api/client/v1/delivery_notes_temporary/${this.$route.params.id}`)

                    this.$printSuccess(this.$t('print.success.delete'))

                    await this.$router.push({name: 'delivery_notes'})
                } catch (err) {
                    Sentry.captureMessage(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            async reassignAllProduct() {
                const confirmDelete = await this.$confirmDialog(this.$t('material.delivery_notes.confirm.change_all_warehouses'))
                if (!confirmDelete) {
                    const foundObject = this.warehouses.find(obj => obj.id === this.deliveryNote.warehouse_id)
                    if (foundObject) {
                        this.addObject = foundObject
                    }
                    return
                }

                if (!this.warehouseData) {
                    return
                }

                for (const unique_id in this.addObject.items) {
                    this.addObject.items[unique_id].warehouse_id = this.warehouseData.id
                }

                await this.updateTemporaryFileDeliveryNote()
            },
            changeCustomVat(val) {
                this.addObject.vat = parseInt(Number(val) * 100)
                this.calculateTotal()
                if (this.$refs.itemsTable) {
                    this.$refs.itemsTable.refresh()
                }
                this.updateTemporaryFileDeliveryNote()
            },
            changeUseCustomVat(val) {
                if (!val) {
                    this.addObject.vat = 0
                    this.customVat = 0
                    this.changeCustomVat(0)
                }
                this.updateTemporaryFileDeliveryNote()
                this.calculateTotal()
            },
            getWarehouseName(value) {
                if (value) {
                    const tmp = this.warehouses.find(x => x.id === value)

                    if (tmp) return tmp.name
                }

                return '/'
            },
            changeDiscount(val) {
                this.addObject.discount = parseInt(Number(val) * 100)
                this.calculateTotal()
                if (this.$refs.itemsTable) {
                    this.$refs.itemsTable.refresh()
                }
                this.updateTemporaryFileDeliveryNote()
            },
            changeDiscountOnItems() {
                if (!this.addObject || !this.addObject.items) {
                    return
                }
                for (const id in this.addObject.items) {
                    const item = this.addObject.items[id]
                    let discount = item.discount

                    if (this.addObject.discount > discount && this.useDiscount) {
                        discount = this.addObject.discount
                    }

                    const vat = parseInt(item.vat)


                    let price =  parseInt(item.price_per_unit * item.quantity)
                    let price_with_discount = parseInt((price * (10000 - discount)) / 10000)
                    let price_with_discount_and_vat = parseInt(((price_with_discount * (10000 + vat)) / 10000))

                    if (item.packaging_unit === 'EUR') {
                        price =  parseInt(item.price)
                        price_with_discount = parseInt((price * (10000 - discount)) / 10000)
                        price_with_discount_and_vat = parseInt((price_with_discount * (10000 + vat)) / 10000)
                    }

                    const numberOfDecilams = this.getNumOfDecimalPlaces(price)

                    price = Math.round(Number(this.$numberRounding(price, numberOfDecilams)) * 1000000)
                    price_with_discount = Math.round(Number(this.$numberRounding(price_with_discount, numberOfDecilams)) * 1000000)
                    price_with_discount_and_vat = Math.round(Number(this.$numberRounding(price_with_discount_and_vat, numberOfDecilams)) * 1000000)


                    this.addObject.items[id].price_with_discount_and_vat = Number(price_with_discount_and_vat)
                    this.addObject.items[id].price_with_discount = Number(price_with_discount)
                    this.addObject.items[id].price = price
                }

                if (this.$refs.itemsTable) {
                    this.$refs.itemsTable.refresh()
                }
            },
            changeUseDiscount(val) {
                if (!val) {
                    this.addObject.discount = 0
                    this.discountNotConverted = 0
                    this.changeDiscount(0)
                    this.updateTemporaryFileDeliveryNote()
                }
            },
            getDiscountValue(item) {
                if (!item) {
                    return ''
                }

                return `${this.$numberRounding(item.price - item.price_with_discount)} €`
            },
            getVatValue(item) {
                if (!item) {
                    return ''
                }

                return `${this.$numberRounding(item.price_with_discount * (item.vat / 10000))} €`
            },
            getIngredientName(value) {
                if (value && this.ingredients) {
                    const tmp = this.ingredients.find(x => x.id === value)

                    if (tmp) return tmp.name
                }

                return '/'
            },
            async checkValidation() {
                return await this.$refs.validationDeliveryNote.validate()
            },
            async confirmDeliveryNote(done) {
                const validation = this.checkValidation()

                if (!validation || !done) {
                    return
                }

                if (this.addObject.items.length <= 0) {
                    this.$printWarning(this.$t('print.warning.no_ingredients_on_delivery_note'))
                    return
                }

                const confirmDelete = await this.$confirmDialog(this.$t('material.delivery_notes.confirm.delivery_note'))
                if (!confirmDelete) return

                try {
                    this.allowConfirm = false

                    await this.$http.post(`/api/client/v1/delivery_notes_temporary/permanent/${this.$route.params.id}`)
                    this.allowConfirm = true
                    this.finished = true
                    this.$printSuccess(this.$t('print.success.add'))
                    await this.$router.push({name: 'delivery_notes'})
                } catch (err) {
                    Sentry.captureMessage(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },

            addItem(payload, edited) {
                const itemCopy = JSON.parse(JSON.stringify(payload))
                if (!edited) {
                    itemCopy.unique_id = this.generateUniqueId()
                }

                if (edited) {
                    const index = this.addObject.items.findIndex(ele => payload.unique_id === ele.unique_id)

                    if (index > -1) { // najde objekt
                        this.addObject.items[index] = itemCopy
                        this.addObject.items.push()
                    } else { // spremenili smo že obstoječo sestavino v drugo sestavino
                        const indexToRemove = this.addObject.items.findIndex(item => item.unique_id === this.editingItemIndex)

                        if (indexToRemove > -1) {
                            this.addObject.items.splice(indexToRemove, 1)
                        }

                        this.addObject.items.push(itemCopy)
                    }
                } else {
                    this.addObject.items.push(itemCopy)
                }
                this.setNumberOfDecimals()
                if (this.$refs.itemsTable) {
                    this.$refs.itemsTable.refresh()
                }
                this.calculateTotal()
                this.updateTemporaryFileDeliveryNote()
            },
            openModalAddProduct() {
                if (this.useCustomVat && !this.customVat) {
                    this.$printWarning(this.$t('print.warning.ddv_not_inserted'))
                } else {
                    this.$refs.deliveryNoteAddProduct.open(null)
                }
            },
            async loadUser() {
                try {
                    const resp = await this.$http.get(`/api/client/v1/users/${this.addObject.user_id}`)
                    this.userData = resp.data

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            async loadData() {
                try {
                    const response1 = this.$http.get('/api/client/v1/suppliers/')
                    const response2 = this.$http.get('/api/client/v1/warehouses/')
                    const response4 = this.$http.get('/api/client/v1/vat_groups/')
                    const response5 =  this.$http.get('/api/client/v1/ingredients/')


                    const response = await Promise.all([response1, response2, response4, response5])
                    this.suppliers = response[0].data ?? []
                    this.warehouses = response[1].data ?? []
                    this.vats = response[2].data ?? []
                    this.ingredients = response[3].data ?? []

                    if (this.warehouses && this.warehouses.length > 0 && !this.editing) {
                        this.warehouseData = this.warehouses[0]
                    }
                    this.setNumberOfDecimals()
                    if (this.$refs.itemsTable) {
                        this.$refs.itemsTable.refresh()
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            async loadSuppliers() {
                try {
                    const response = await this.$http.get('/api/client/v1/suppliers/')
                    this.suppliers = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            addSupplier() {
                this.$refs.supplierModal.open()
            },
            async removeItemFromTable(data) {

                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    const index = this.addObject.items.findIndex(ele => ele.unique_id === data.unique_id)
                    if (index > -1) {
                        this.addObject.items.splice(index, 1)
                    }
                    this.updateTemporaryFileDeliveryNote()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            editItem(data) {
                this.editingItemIndex = data.unique_id
                this.$refs.deliveryNoteAddProduct.open(data)
                this.updateTemporaryFileDeliveryNote()
            },
            calculateTotal() {
                this.changeDiscountOnItems()

                this.deliveryNotePrice.price_without_vat = 0
                this.deliveryNotePrice.total = 0
                this.deliveryNotePrice.discount_value = 0
                this.deliveryNotePrice.vat_value = 0
                this.deliveryNotePrice.vat_by_rate = {}
                this.addObject.price = 0
                this.addObject.price_with_discount = 0
                this.addObject.price_with_discount_and_vat = 0
                for (const id in this.addObject.items) {

                    const item = this.addObject.items[id]
                    let vat = item.vat

                    let discount = Number(item.discount)

                    if (this.useDiscount && this.addObject.discount > discount) {
                        discount = Number(this.addObject.discount)
                    }

                    if (this.useCustomVat) {
                        vat = Number(this.customVat) * 100
                    }

                    const quantity = item.quantity

                    let price =  Math.trunc(item.price_per_unit * quantity)
                    let price_with_discount = Math.trunc((price * (10000 - discount)) / 10000)
                    let price_with_discount_and_vat = Math.trunc((price_with_discount * (10000 + vat)) / 10000)
                    let discountValue = Number((item.price_per_unit * item.quantity) * (discount / 10000))
                    let total_price_without_vat = ((item.price_per_unit * quantity * (10000 - discount)) / 10000)

                    if (item.packaging_unit === 'EUR') {
                        price =  item.price
                        price_with_discount = Math.trunc((price * (10000 - discount)) / 10000)
                        price_with_discount_and_vat = Math.trunc((price_with_discount * (10000 + vat)) / 10000)
                        total_price_without_vat = price_with_discount
                        discountValue = Number((price) * (discount / 10000))
                    }
                    const numberOfDecilams = this.getNumOfDecimalPlaces(price)

                    price = Math.round(Number(this.$numberRounding(price, numberOfDecilams)) * 1000000)
                    price_with_discount = Math.round(Number(this.$numberRounding(price_with_discount, numberOfDecilams)) * 1000000)
                    price_with_discount_and_vat = Math.round(Number(this.$numberRounding(price_with_discount_and_vat, numberOfDecilams)) * 1000000)
                    total_price_without_vat = Math.round(Number(this.$numberRounding(total_price_without_vat, numberOfDecilams)) * 1000000)
                    discountValue = Math.round(Number(this.$numberRounding(discountValue, numberOfDecilams)) * 1000000)


                    this.addObject.items[id].price = price
                    this.addObject.items[id].price_with_discount = price_with_discount
                    this.addObject.items[id].price_with_discount_and_vat = price_with_discount_and_vat
                    if (this.useCustomVat) {
                        this.addObject.items[id].vat = vat
                    }

                    this.addObject.price += price
                    this.addObject.price_with_discount += price_with_discount
                    this.addObject.price_with_discount_and_vat += price_with_discount_and_vat

                    this.deliveryNotePrice.discount_value += discountValue
                    this.deliveryNotePrice.total += total_price_without_vat * ((100 + (Number(vat) / 100)) / 100)
                    this.deliveryNotePrice.price_without_vat += total_price_without_vat

                    const vat_value = Number(item.price_with_discount * (item.vat / 10000))
                    this.deliveryNotePrice.vat_value += vat_value

                    if (!(vat in this.deliveryNotePrice.vat_by_rate)) {
                        this.deliveryNotePrice.vat_by_rate[vat] = 0
                    }
                    this.deliveryNotePrice.vat_by_rate[vat] += vat_value
                }
            },

            getIndexById(id) {
                return this.versions.findIndex(ele => ele.id === id)
            },
            async setSelection() {
                if (!this.compareItemsArray(this.addObject.items, this.deliveryNote.items)) return this.$printError(this.$t('print.error.save_before_switch'))
                await this.openEditing(this.selected, this.selected, true)
            },
            async openEditing(data, deliveryNote, editing) {
                this.showLoader = true
                this.addObject = data
                this.editing = editing
                this.setNumberOfDecimals()

                await this.loadData()
                this.deliveryNote = deliveryNote

                if (editing) { // ne delamo nove dobavnice
                    this.addObject.items = data.items
                    this.selected = deliveryNote
                    this.apiUrl = `/api/reports/v1/printout/delivery_note/${  this.addObject.id  }`
                    this.fileName =  `Dobavnica_${this.addObject.code}.pdf`


                    //this.selectedColumns.splice(this.selectedColumns.length - 1, 0, { headerName: this.$t('general.changes'), editable: false, field: 'temporary_changes', cellRenderer: 'DisplayTemporaryEditing'})
                    const exists = this.selectedColumns.some(column => column.field === 'temporary_changes')

                    if (!exists) {
                        this.selectedColumns.splice(this.selectedColumns.length - 1, 0, {
                            headerName: this.$t('table_fields.changes'),
                            editable: false,
                            field: 'temporary_changes',
                            cellRenderer: 'DisplayTemporaryEditing'
                        })
                    }
                } else { // delamo novo dobavnico
                    this.deliveryNote.items = []
                }

                if (data.warehouse_id !== this.$NilObjectId) {
                    await this.loadUser()
                    this.confirmed = data.confirmed

                    this.addObject.warehouse_id = data.warehouse_id

                    this.useDiscount = this.addObject.use_discount
                    this.discountNotConverted = Number(this.addObject.discount) / 100
                    this.useCustomVat = this.addObject.use_vat
                    this.customVat =  Number(this.addObject.vat) / 100

                    if (this.suppliers && this.suppliers.length > 0) {
                        this.supplierData = this.suppliers.find(ele => ele.id === this.addObject.supplier_id)
                    }

                    if (this.warehouses && this.warehouses.length > 0) {
                        this.warehouseData = this.warehouses.find(ele => ele.id === this.addObject.warehouse_id)
                    }

                    this.discount_value = this.deliveryNote.price - this.deliveryNote.price_with_discount
                }
                if (this.$refs.itemsTable) {
                    this.$refs.itemsTable.refresh()
                }
                this.calculateTotal()
                this.showLoader = false
            },
            getNumOfDecimalPlaces(data) {
                const dataConverted = String(this.$numberRounding(data, 6))
                const dataLength = dataConverted.length
                let decimals = 0
                for (let i = 0; i <= 5; i++) {
                    if (dataConverted[dataLength - i - 1] === '0') {
                        decimals++
                    } else {
                        break
                    }
                }
                return 6 - decimals
            },
            setNumberOfDecimals() {
                const decimalPlaces1 = this.addObject.items.map((item) => {
                    return this.getNumOfDecimalPlaces(item.price_per_unit)
                })
                const decimalPlaces2 = this.addObject.items.map((item) => {
                    return this.getNumOfDecimalPlaces(item.price_with_discount_and_vat)
                })
                this.addObject.decimals = Math.max(...[...decimalPlaces1, ...decimalPlaces2])
            },
            async loadVersions() {
                try {
                    const res = await this.$http.get(`/api/client/v1/delivery_notes_history/${this.deliveryNoteId}`)
                    this.versions = res.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                }
            }
        },
        mounted() {
            this.userData = {
                name: ''
            }
            this.deliveryNoteId = this.$route.params.id

            if (!this.editing) {
                this.userData = this.$store.getters['user/userData']

                if (!this.userData) {
                    this.userData = {
                        name: ''
                    }
                }

                this.loadData()
                this.loadVersions()
            }
        },
        async beforeDestroy() {
            if (!this.editing) return
            if (this.finished) return
            const payload = this.getPayloadData()
            payload.edited = false
            await this.$http.post(`/api/client/v1/delivery_notes_temporary/${this.deliveryNoteId}`, payload)
        }

    }
</script>

<style lang="scss" scoped>

.text-price-bold{
  font-weight: bold;
  font-size: 17px;
}

.text-price{
  font-size: 17px;
}

.data-border{
  border: 2px solid #646464;
  margin: 5px;
  padding: 10px;
  position: relative;
}

.border-text{
  position: absolute;
  top: -12px;
  //background: white;
  padding: 0 15px;
  font-weight: bold;

}

.dark-layout .border-text{
  background: #3e3e3e;
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
