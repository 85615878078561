<template>
    <b-modal ref="addProductToDeliveryNote" :title="$t('material.adding_ingredient')" size="lg" @ok="validate">
        <validation-observer ref="validationAddItem">
            <div>
                <b-form-group :label="$t('general.ingredient')">
                    <validation-provider #default="{ errors }" :name="$t('general.ingredient')" rules="required">
                        <v-select ref="ingredient" class="ingredient" v-model="selectedIngredient" label="name" :filter="search" :options="ingredients"  @option:selected="changeDefault" :disabled="editing" :clearable="false">
                            <template #option="item">
                                {{item.ingredient_code}} - {{item.name}}
                            </template>
                            <template #selected-option="item">
                                {{item.ingredient_code}} - {{item.name}}
                            </template>
                        </v-select>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>

                <div class="row">
                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('form_data.quantity')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.quantity')" :rules="getQuantityRules">
                                <b-form-input type="number" v-model.number="quantity" :disabled="!selectedIngredient || deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro" lazy max="1000000"></b-form-input>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>

                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('form_data.packaging')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.packaging')" :rules="getPackagingRules">
                                <b-form-input type="number" v-model.number="packaging" :disabled="!selectedIngredient || deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro"></b-form-input>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>

                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('form_data.unit')">
                            <validation-provider #default="{ errors }" :name="$t('form_data.unit')" rules="required">
                                <v-select v-model="deliveryNoteItem.packaging_unit" :disabled="!selectedIngredient || deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro" appendToBody label="name" :options="types" class="unit-select" :clearable="false"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('material.delivery_notes.net_price_per_unit')">
                            <validation-provider #default="{ errors }" :name="$t('material.delivery_notes.net_price_per_unit')" rules="required|min_value:0">
                                <b-input-group append="€">
                                    <b-form-input type="number" v-model.number="price_per_unit" :disabled="!selectedIngredient || deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro" lazy min="0"></b-form-input>
                                </b-input-group>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('material.delivery_notes.gross_price_per_unit')">
                            <validation-provider #default="{ errors }" :name="$t('material.delivery_notes.gross_price_per_unit')" rules="required|min_value:0">
                                <b-input-group append="€">
                                    <b-form-input type="number" v-model.number="price_per_unit_bruto" :disabled="!selectedIngredient || deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro" lazy min="0"></b-form-input>
                                </b-input-group>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('material.delivery_notes.vat_value_per_unit')">
                            <b-input-group append="€">
                                <b-form-input v-model="vat_value_single" :disabled="true"></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('material.delivery_notes.net_price_total')">
                            <validation-provider #default="{ errors }" :name="$t('material.delivery_notes.net_price_total')" rules="required|min_value:0">
                                <b-input-group append="€">
                                    <b-form-input type="number" v-model.number="total_price_neto" :disabled="!selectedIngredient" lazy min="0"></b-form-input>
                                </b-input-group>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('material.delivery_notes.gross_price_total')">
                            <validation-provider #default="{ errors }" :name="$t('material.delivery_notes.gross_price_total')" rules="required|min_value:0">
                                <b-input-group append="€">
                                    <b-form-input type="number" v-model.number="total_price" :disabled="!selectedIngredient" lazy min="0"></b-form-input>
                                </b-input-group>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-4">
                        <b-form-group :label="$t('material.delivery_notes.vat_value_total')">
                            <b-input-group append="€">
                                <b-form-input v-model="vat_value" :disabled="true"></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-6">
                        <b-form-group :label="$t('general.discount')">
                            <validation-provider #default="{ errors }" :name="$t('general.discount')" rules="required|min_value:0|max_value:100|decimalCustom:2|mustBePositive:true">
                                <b-input-group append="%">
                                    <b-form-input type="number" v-model.number="discount" :disabled="!selectedIngredient || deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro" min="0" max="100" lazy></b-form-input>
                                </b-input-group>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                    <div class="col-6 col-md-6">
                        <b-form-group :label="$t('general.tax_rate')">
                            <b-input-group append="%">
                                <b-form-input v-model="vat_percentage" :disabled="true"></b-form-input>
                            </b-input-group>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-12">
                        <b-form-group :label="$t('general.warehouse')">
                            <validation-provider #default="{ errors }" :name="$t('general.warehouse')" rules="required">
                                <v-select appendToBody v-model="warehouseData" label="name" :options="warehouses" @input="loadData" :disabled="!selectedIngredient" :clearable="false"/>
                                <small class="text-danger">{{errors[0]}}</small>
                            </validation-provider>
                        </b-form-group>
                    </div>
                </div>
                <div class="row" v-if="selectedIngredient && deliveryNoteItem.packaging_unit.name !== PackagingUnit.Euro">
                    <div class="col-6 col-md-4">
                        <span>{{$t('material.delivery_notes.last_price_at_supplier')}}</span>
                        <b-input-group style="padding-top: calc(0.438rem + 1px);">
                            <b-form-input style="max-width: 150px; border: none" :disabled="true" v-model="selectedAveragePrice" />
                            <b-input-group-append class="px-1 d-flex align-items-center append-text">
                                <div class="d-flex text-center flex-column" v-if="selectedIngredient && selectedIngredient.average_price > 0 && !isPriceDifferenceZero">
                                    <div style="font-size: 9px; color: #de8f8f;" :class="{'text-danger': priceDifferenceNumber > 0, 'text-success': priceDifferenceNumber < 0}">
                                        {{$t('material.delivery_notes.difference_with_current')}}
                                    </div>
                                    <div style="font-size: 11px; font-weight: bold; color: #f87a7a; " :class="{'text-danger': priceDifferenceNumber > 0, 'text-success': priceDifferenceNumber < 0}">
                                        {{ priceDifferenceNumber }}€ ({{ priceDifferencePercent }}%)
                                    </div>
                                </div>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    <div class="col-6 col-md-4">
                        <span>{{$t('material.delivery_notes.last_discount')}}</span>
                        <b-input-group style="padding-top: calc(0.438rem + 1px);">
                            <b-form-input style="max-width: 150px; border: none" :disabled="true" v-model="selectedDiscount" />
                            <b-input-group-append class="px-1 d-flex align-items-center append-text">
                                <div class="d-flex text-center flex-column" v-if="selectedIngredient && discountDifference !== 0 && selectedIngredient.discount !== 0">
                                    <div style="font-size: 9px; color: #de8f8f;" :class="{'text-danger': discountDifference > 0, 'text-success': discountDifference < 0}">
                                        {{$t('material.delivery_notes.difference_with_current')}}
                                    </div>
                                    <div style="font-size: 11px; font-weight: bold; color: #f87a7a; " :class="{'text-danger': discountDifference > 0, 'text-success': discountDifference < 0}">
                                        {{ discountDifference }}%
                                    </div>
                                </div>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    <div class="col-6 col-md-4">
                        <span>{{$t('material.delivery_notes.last_price_with_discount')}}</span>
                        <b-input-group style="padding-top: calc(0.438rem + 1px);">
                            <b-form-input style="max-width: 150px; border: none" :disabled="true" v-model="selectedAveragePriceWithDiscount" />
                            <b-input-group-append class="px-1 d-flex align-items-center append-text">
                                <div class="d-flex text-center flex-column" v-if="selectedIngredient && selectedIngredient.average_price > 0 && !isPriceWithDiscountDifferenceZero">
                                    <div style="font-size: 9px; color: #de8f8f;" :class="{'text-danger': priceWithDiscountDifference > 0, 'text-success': priceWithDiscountDifference < 0}">
                                        {{$t('material.delivery_notes.difference_with_current')}}
                                    </div>
                                    <div style="font-size: 11px; font-weight: bold; color: #f87a7a; " :class="{'text-danger': priceWithDiscountDifference > 0, 'text-success': priceWithDiscountDifference < 0}">
                                        {{ priceWithDiscountDifference }}€
                                    </div>
                                </div>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                </div>
            </div>
        </validation-observer>

        <template #modal-footer>
            <b-button variant="secondary" @click="hide">
                {{ $t('general.cancel') }}
            </b-button>
            <b-button variant="primary" @click="addItem">
                <span v-if="!editing">{{ $t('general.add') }}</span>
                <span v-else>{{ $t('general.edit') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>


<script>
    import vSelect from 'vue-select'
    import {BModal, BFormGroup, BFormInput, BInputGroup, BButton, BInputGroupAppend } from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, minValue, decimalCustom, mustBePositive } from '@validations'
    import {minNumber} from '@core/utils/validations/validations'
    import * as Sentry from '@sentry/vue'
    import {getMoneyTypes, getPieceTypes, getVolumeTypes, getWeightTypes} from '@/libs/constants/ingredients.js'
    import {PackagingUnit} from '@/libs/enums/PackagingUnit'

    export default {
        components:{
            vSelect,
            BModal,
            BFormGroup,
            BFormInput,
            BInputGroup,
            BButton,
            BInputGroupAppend,
            ValidationProvider,
            ValidationObserver
        },
        data() {
            return {
                vat_value_single_variable: null,
                warehouseData: null,
                ingredients: [],
                rawIngredients: [],
                selectedIngredient: null,
                uniqueId: null,
                deliveryNoteItem: {
                    packaging_unit: '',
                    packaging: '',
                    quantity: '',
                    price_per_unit: '',
                    price_per_unit_bruto: '',
                    total_price_neto: '',
                    vat_total: '',
                    vat_value: '',
                    discount: 0,
                    total_price: ''
                },
                deliveryNoteItemInternal: {
                    price_per_unit: '',
                    price_per_unit_bruto: '',
                    total_price_neto: '',
                    total_price: '',
                    discount: '',
                    quantity: ''
                },
                types: [],
                editing: false,
                required,
                decimalCustom,
                minNumber,
                minValue,
                mustBePositive,
                num_of_decimals: 2,
                warehouse_stock_type: null,
                PackagingUnit
            }
        },
        props:{
            supplier: {
                type: Object
            },
            useCustomVat: {
                type: Boolean
            },
            customVat: {

            },
            warehouses: {
                type: Array
            },
            selectedWarehouse: {
                type: Object
            }
        },
        computed: {
            money_types() {
                return getMoneyTypes()
            },
            weight_types() {
                return getWeightTypes()
            },
            volume_types() {
                return getVolumeTypes()
            },
            piece_types() {
                return getPieceTypes()
            },
            selectedAveragePrice() {
                if (this.selectedIngredient) {
                    return `${this.$prettyNumberRounding(this.selectedIngredient.average_price, 4)}€`
                }

                return ''
            },
            selectedDiscount() {
                if (this.selectedIngredient) {
                    return `${(this.selectedIngredient.discount / 100).toFixed(2)}%`
                }
                return ''
            },
            selectedAveragePriceWithDiscount() {
                if (this.selectedIngredient) {
                    return `${this.$prettyNumberRounding(this.selectedIngredient.price_with_discount_and_vat, 4)}€`
                }
                return ''
            },
            priceDifferenceNumber() {
                return this.$prettyNumberRounding(this.deliveryNoteItemInternal.price_per_unit - this.selectedIngredient.average_price, 6)
            },
            priceDifferencePercent() {
                return (((this.deliveryNoteItemInternal.price_per_unit - this.selectedIngredient.average_price) / this.selectedIngredient.average_price) * 100).toFixed(2)
            },
            discountDifference() {
                return (this.selectedIngredient.discount / 100) - this.deliveryNoteItem.discount
            },
            priceWithDiscountDifference() {
                return this.$prettyNumberRounding(this.deliveryNoteItemInternal.price_per_unit_bruto - this.selectedIngredient.price_with_discount_and_vat, 2)
            },
            isPriceDifferenceZero() {
                return Number(this.priceDifferenceNumber) === 0
            },
            isPriceWithDiscountDifferenceZero() {
                return Number(this.priceWithDiscountDifference) === 0
            },
            packaging: {
                get() {
                    return this.deliveryNoteItem.packaging
                },
                async set(newValue) {

                    this.deliveryNoteItem.packaging = newValue
                    if (!(await this.validate())) {
                        return
                    }
                    this.calcAllValues()
                }
            },
            quantity: {
                get() {
                    return this.deliveryNoteItem.quantity
                },
                async set(newValue) {
                    if (newValue < 0) {
                        this.deliveryNoteItem.quantity = newValue
                        return
                    }
                    this.deliveryNoteItem.quantity = newValue
                    this.deliveryNoteItemInternal.quantity = newValue
                    if (!(await this.validate())) return
                    this.calcAllValues()
                }
            },
            discount: {
                get() {
                    return this.deliveryNoteItem.discount
                },
                async set(newValue) {
                    this.deliveryNoteItem.discount = newValue
                    this.$nextTick(() => {
                        if (this.$refs.validationAddItem) {
                            this.$refs.validationAddItem.validate().then((success) =>  {
                                if (success) {
                                    this.calcAllValues()
                                }
                            })
                        }
                    })
                }
            },
            price_per_unit: {
                get() {
                    return this.$numberRounding(this.deliveryNoteItemInternal.price_per_unit, this.num_of_decimals)
                },
                set(newValue) {
                    if (newValue < 0) {
                        this.deliveryNoteItemInternal.price_per_unit = Math.trunc(newValue * 1000000)
                        return
                    }
                    this.setNumOfDecimalPlaces(newValue)
                    this.deliveryNoteItemInternal.price_per_unit = Math.trunc(newValue * 1000000)
                    this.calcAllValues()
                }
            },
            price_per_unit_bruto: {
                get() {
                    return this.$numberRounding(this.deliveryNoteItemInternal.price_per_unit_bruto, this.num_of_decimals)
                },
                set(newValue) {
                    if (newValue < 0) {
                        this.deliveryNoteItemInternal.price_per_unit_bruto = Math.trunc(newValue * 1000000)
                        return
                    }
                    this.setNumOfDecimalPlaces(newValue)
                    this.deliveryNoteItemInternal.price_per_unit = Math.trunc(((newValue * 1000000) / (100 - this.deliveryNoteItem.discount) * 100) / (100 + this.vat_percentage) * 100)
                    this.calcAllValues()
                }
            },
            total_price: {
                get() {
                    return this.$numberRounding(this.deliveryNoteItemInternal.total_price, this.num_of_decimals)
                },
                set(newValue) {
                    if (newValue < 0) {
                        this.deliveryNoteItemInternal.total_price = Math.trunc(newValue * 1000000)
                        return
                    }
                    this.setNumOfDecimalPlaces(newValue)
                    this.deliveryNoteItemInternal.price_per_unit = Math.trunc(((newValue * 1000000) / (100 - this.deliveryNoteItem.discount) * 100) / this.deliveryNoteItem.quantity / (100 + this.vat_percentage) * 100)
                    this.calcAllValues(newValue, 2)
                }
            },
            total_price_neto: {
                get() {
                    return this.$numberRounding(this.deliveryNoteItemInternal.total_price_neto, this.num_of_decimals)
                },
                set(newValue) {
                    if (newValue < 0) {
                        this.deliveryNoteItemInternal.total_price_neto = Math.trunc(newValue * 1000000)
                        return
                    }
                    this.setNumOfDecimalPlaces(newValue)
                    this.deliveryNoteItemInternal.price_per_unit = Math.trunc(((newValue * 1000000) / (100 - this.deliveryNoteItem.discount) * 100) / this.deliveryNoteItem.quantity)
                    this.calcAllValues(newValue, 1)
                }
            },
            vat_value() {
                if (this.deliveryNoteItem.quantity && this.deliveryNoteItem.packaging && this.deliveryNoteItem.price_per_unit && this.deliveryNoteItemInternal.price_per_unit > 0 && this.deliveryNoteItem.discount >= 0) {
                    let price = 0
                    if (this.deliveryNoteItem.quantity >= 0) {
                        price = Number(this.deliveryNoteItem.quantity) * Number(this.deliveryNoteItemInternal.price_per_unit)
                    } else {
                        price = Number(this.deliveryNoteItemInternal.quantity) * Number(this.deliveryNoteItemInternal.price_per_unit)
                    }
                    return this.$numberRounding(((price * this.vat_percentage) / 100) * ((100 - this.deliveryNoteItem.discount) / 100), this.num_of_decimals)
                }

                return 0.00
            },
            vat_value_single() {

                if (this.deliveryNoteItem.discount >= 0 && this.deliveryNoteItem.price_per_unit > 0 && this.vat_percentage >= 0 && this.num_of_decimals >= 0) {
                    return this.$numberRounding(((this.deliveryNoteItemInternal.price_per_unit * this.vat_percentage) / 100) * ((100 - this.deliveryNoteItem.discount) / 100), this.num_of_decimals)
                }

                return 0.00
            },
            vat_percentage() {

                if (!this.selectedIngredient) {
                    return
                }

                if (!this.selectedIngredient || !this.selectedIngredient.vat_group || this.selectedIngredient.vat_group.percentage === undefined || this.selectedIngredient.vat_group.percentage === null) {
                    return
                }


                if (this.useCustomVat) {
                    return Number(this.customVat)
                }
                return Number(this.selectedIngredient.vat_group.percentage / 100)
            },
            getPackagingRules() {
                if (this.deliveryNoteItem.packaging_unit !== null && this.deliveryNoteItem.packaging_unit) {
                    if (this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Gram || this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Milliliter) {
                        return 'required|min_value:1|decimalCustom:0|mustBePositive:true|min_number:0'
                    } else if (this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Decagram || this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Decilitre || this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Piece) {
                        return 'required|decimalCustom:2|mustBePositive:true|min_number:0'
                    } else if (this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Kilogram || this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Liter) {
                        return 'required|decimalCustom:3|mustBePositive:true|min_number:0'
                    } else if (this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro) {
                        return 'required|decimalCustom:6|mustBePositive:true|min_number:0'
                    }

                    return 'required|min_value:1|decimalCustom:0|mustBePositive:true|min_number:0'
                } else {
                    return ''
                }
            },
            getQuantityRules() {
                if (this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro) {
                    return 'required|max_value:1000000|min_value:1'
                } else {
                    return 'required|max_value:1000000|decimalCustom:0|min_value:1'
                }
            }
        },
        methods:{
            search(items, search) {

                const returnItems = []

                for (const item of items) {

                    const text = `${item.ingredient_code  } - ${  item.name}`

                    if (text.toLowerCase().includes(search.toLowerCase())) {
                        returnItems.push(item)
                    }

                }

                return returnItems
            },
            getNumOfDecimalPlaces(data) {
                const dataConverted = String(this.$numberRounding(data, 6))
                const dataLength = dataConverted.length
                let decimals = 0
                for (let i = 0; i <= 5; i++) {
                    if (dataConverted[dataLength - i - 1] === '0') {
                        decimals++
                    } else {
                        break
                    }
                }
                return 6 - decimals
            },
            setNumOfDecimalPlaces(newValue) {
                this.num_of_decimals = String(newValue).split('.')[1] ? String(newValue).split('.')[1].length : 2
                if (this.num_of_decimals <= 2) {
                    this.num_of_decimals = 2
                } else if (this.num_of_decimals >= 6) {
                    this.num_of_decimals = 6
                }
            },
            calcAllValuesInitial() {
                if (this.deliveryNoteItem.discount < 0) this.deliveryNoteItem.discount = 0
                if (this.deliveryNoteItem.discount > 100) this.deliveryNoteItem.discount = 100
                if (this.deliveryNoteItem.quantity < 0) this.deliveryNoteItem.quantity = 0
                if (this.deliveryNoteItemInternal.price_per_unit < 0) return

                if (this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro && this.warehouse_stock_type.money_input_type === 1) {
                    this.deliveryNoteItem.quantity = this.deliveryNoteItem.packaging
                    this.deliveryNoteItemInternal.total_price_neto = this.deliveryNoteItem.packaging * 1000000
                    this.packaging = 1
                } else if (this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro && this.warehouse_stock_type.money_input_type === 2) {
                    this.deliveryNoteItem.quantity = this.deliveryNoteItem.packaging
                    this.deliveryNoteItemInternal.total_price = this.deliveryNoteItem.packaging * 1000000
                    this.packaging = 1
                }

                this.deliveryNoteItemInternal.price_per_unit_bruto = (this.deliveryNoteItemInternal.price_per_unit * (100 + this.vat_percentage) / 100) * ((100 - this.deliveryNoteItem.discount) / 100)
                this.deliveryNoteItemInternal.total_price = this.deliveryNoteItem.quantity * this.deliveryNoteItemInternal.price_per_unit_bruto
                this.deliveryNoteItemInternal.total_price_neto = this.deliveryNoteItem.quantity * this.deliveryNoteItemInternal.price_per_unit
                //Nastavimo še ceno v main objektu, ki se pošilja na api
                this.deliveryNoteItem.price_per_unit = this.deliveryNoteItemInternal.price_per_unit


                if (this.num_of_decimals === '') {
                    const decimals1 = this.getNumOfDecimalPlaces(this.deliveryNoteItemInternal.price_per_unit)
                    const decimals2 = this.getNumOfDecimalPlaces(this.deliveryNoteItemInternal.price_per_unit)
                    const decimals3 = this.getNumOfDecimalPlaces(this.deliveryNoteItemInternal.total_price)
                    const decimals4 = this.getNumOfDecimalPlaces(this.deliveryNoteItemInternal.total_price_neto)

                    const allDecimals = [decimals1, decimals2, decimals3, decimals4]
                    this.num_of_decimals = Math.max(...allDecimals)
                }

            },
            calcAllValues(newValue = 0, type = 0) {
                if (this.deliveryNoteItem.discount < 0) this.deliveryNoteItem.discount = 0
                if (this.deliveryNoteItem.discount > 100) this.deliveryNoteItem.discount = 100
                if (this.deliveryNoteItem.quantity < 0) this.deliveryNoteItem.quantity = 0
                if (this.deliveryNoteItemInternal.price_per_unit < 0) return

                newValue =  Math.round(newValue * 1000000)

                if (this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro) {
                    this.deliveryNoteItemInternal.price_per_unit = 1000000
                    this.deliveryNoteItemInternal.price_per_unit_bruto = ((((1000000 * (100 + this.vat_percentage)) / 100) * (100 - this.deliveryNoteItem.discount)) / 100)

                    if (type === 1) {
                        this.deliveryNoteItemInternal.total_price_neto = newValue
                        this.deliveryNoteItemInternal.total_price = (newValue * (100 + this.vat_percentage) / 100)
                    } else if (type === 2) {
                        this.deliveryNoteItemInternal.total_price = newValue
                        this.deliveryNoteItemInternal.total_price_neto = (newValue / ((100 + this.vat_percentage) / 100))
                    }

                    if (this.warehouse_stock_type.money_input_type === 1) {
                        this.deliveryNoteItem.quantity = Number(this.$numberRounding(this.deliveryNoteItemInternal.total_price_neto, this.num_of_decimals))
                    } else if (this.warehouse_stock_type.money_input_type === 2) {
                        this.deliveryNoteItem.quantity = Number(this.$numberRounding(this.deliveryNoteItemInternal.total_price, this.num_of_decimals))
                    }

                } else {
                    this.deliveryNoteItemInternal.price_per_unit_bruto = (this.deliveryNoteItemInternal.price_per_unit * (100 + this.vat_percentage) / 100) * ((100 - this.deliveryNoteItem.discount) / 100)
                    this.deliveryNoteItemInternal.total_price = this.deliveryNoteItem.quantity * this.deliveryNoteItemInternal.price_per_unit_bruto
                    this.deliveryNoteItemInternal.total_price_neto = this.deliveryNoteItem.quantity * this.deliveryNoteItemInternal.price_per_unit
                }


                //Nastavimo še ceno v main objektu, ki se pošilja na api
                this.deliveryNoteItem.price_per_unit = this.deliveryNoteItemInternal.price_per_unit

                if (this.num_of_decimals === '') {
                    const decimals1 = this.getNumOfDecimalPlaces(this.deliveryNoteItemInternal.price_per_unit)
                    const decimals2 = this.getNumOfDecimalPlaces(this.deliveryNoteItemInternal.price_per_unit)
                    const decimals3 = this.getNumOfDecimalPlaces(this.deliveryNoteItemInternal.total_price)
                    const decimals4 = this.getNumOfDecimalPlaces(this.deliveryNoteItemInternal.total_price_neto)

                    const allDecimals = [decimals1, decimals2, decimals3, decimals4]
                    this.num_of_decimals = Math.max(...allDecimals)
                }

            },
            async validate() {
                return await this.$refs.validationAddItem.validate()
            },
            async addItem() {
                if (!(await this.validate())) return

                let price_per_unit = this.deliveryNoteItemInternal.price_per_unit

                let packaging = this.deliveryNoteItem.packaging * this.deliveryNoteItem.packaging_unit.factor
                let quantity = parseInt(this.deliveryNoteItem.quantity)
                const discount =  Math.round(this.deliveryNoteItem.discount * 100)

                if (this.deliveryNoteItem.packaging_unit.name === PackagingUnit.Euro) {
                    packaging = this.deliveryNoteItem.quantity *  this.deliveryNoteItem.packaging_unit.factor
                    quantity = 1
                    price_per_unit = 1000000
                }

                const price =  Math.round(Number(this.$numberRounding(Math.round(price_per_unit * this.deliveryNoteItem.quantity), this.num_of_decimals)) * 1000000)
                const price_with_discount =  Math.round(Number(this.$numberRounding(Math.round((price * (10000 - discount)) / 10000), this.num_of_decimals)) * 1000000)

                const vatItem = Number(this.selectedIngredient.vat_group.percentage)

                const vatToUse = Math.round(this.vat_percentage * 100)

                const price_with_discount_and_vat =   Math.round(Number(this.$numberRounding(Math.round((price_with_discount * (10000 + vatToUse)) / 10000), this.num_of_decimals)) * 1000000)

                const payload = {
                    id: this.deliveryNoteItem.id,
                    packaging_unit: this.deliveryNoteItem.packaging_unit.name,
                    packaging,
                    quantity,
                    price_per_unit: Math.round(price_per_unit),
                    price,
                    discount,
                    price_with_discount,
                    price_with_discount_and_vat,
                    stock: parseInt(packaging) * parseInt(quantity),
                    warehouse_id: this.warehouseData.id,
                    ingredient_id: this.selectedIngredient.id,
                    vat: vatItem,
                    unique_id: this.unique_id
                }

                this.$emit('addItem', payload, this.editing)
                this.hide()
            },
            changeDefault() {
                if (!this.selectedIngredient) {
                    return
                }

                this.deliveryNoteItem.quantity = '1'
                this.deliveryNoteItem.packaging = ''
                this.deliveryNoteItem.price_per_unit = ''
                this.deliveryNoteItem.discount = 0
                this.deliveryNoteItemInternal = {
                    price_per_unit: '',
                    price_per_unit_bruto: '',
                    total_price_neto: '',
                    total_price: '',
                    discount: '',
                    quantity: ''
                }
                this.calcAllValues()

                if (this.selectedIngredient.amount_type === 'piece') {
                    this.deliveryNoteItem.packaging_unit = this.piece_types.find(ele => ele.factor === 100)
                    this.types = this.piece_types
                } else if (this.selectedIngredient.amount_type === 'volume') {
                    this.deliveryNoteItem.packaging_unit = this.volume_types.find(ele => ele.name === this.selectedIngredient.packaging_unit)
                    this.types = this.volume_types
                } else if (this.selectedIngredient.amount_type === 'weight') {
                    this.deliveryNoteItem.packaging_unit = this.weight_types.find(ele => ele.name === this.selectedIngredient.packaging_unit)
                    this.types = this.weight_types
                } else if (this.selectedIngredient.amount_type === 'money') {
                    this.deliveryNoteItem.packaging_unit = this.money_types.find(ele => ele.name === this.selectedIngredient.packaging_unit)
                    this.types = this.money_types
                }

                if (this.deliveryNoteItem.packaging_unit) {
                    this.deliveryNoteItem.packaging = this.selectedIngredient.packaging / this.deliveryNoteItem.packaging_unit.factor
                }

                if (this.selectedIngredient.average_price > 0) {
                    this.deliveryNoteItem.price_per_unit = this.$numberRounding(this.selectedIngredient.average_price, 4)
                    this.deliveryNoteItemInternal.price_per_unit = this.selectedIngredient.average_price

                    if (this.num_of_decimals < 4) {
                        this.num_of_decimals = 4
                    }
                    this.calcAllValues()
                } else {
                    this.deliveryNoteItem.price_per_unit = null
                }

                if (this.selectedIngredient.discount > 0) {
                    this.deliveryNoteItem.discount = this.selectedIngredient.discount / 100
                    this.deliveryNoteItemInternal.discount = this.selectedIngredient.discount / 100
                    this.calcAllValues()
                } else {
                    this.deliveryNoteItem.discount = 0
                }

                if (this.selectedIngredient.amount_type === 'money') {
                    this.deliveryNoteItemInternal.price_per_unit = 1000000
                    this.num_of_decimals = 2
                    this.calcAllValues()
                }

                this.$refs.validationAddItem.reset()
            },
            findTypeOfIngredient() {
                if (!this.selectedIngredient) {
                    return
                }

                let factor = 1
                let type = null
                if (this.deliveryNoteItem.packaging_unit === PackagingUnit.Milliliter || this.deliveryNoteItem.packaging_unit === PackagingUnit.Decilitre || this.deliveryNoteItem.packaging_unit === PackagingUnit.Liter) {
                    type = this.volume_types.find(ele => ele.name === this.deliveryNoteItem.packaging_unit)
                    this.types = this.volume_types
                } else if (this.deliveryNoteItem.packaging_unit === PackagingUnit.Gram || this.deliveryNoteItem.packaging_unit === PackagingUnit.Decagram || this.deliveryNoteItem.packaging_unit === PackagingUnit.Kilogram) {
                    type = this.weight_types.find(ele => ele.name === this.deliveryNoteItem.packaging_unit)
                    this.types = this.weight_types
                }  else if (this.deliveryNoteItem.packaging_unit === PackagingUnit.Euro) {
                    type = this.money_types.find(ele => ele.name === this.deliveryNoteItem.packaging_unit)
                    this.types = this.money_types
                } else {
                    type = this.piece_types.find(ele => ele.name === this.deliveryNoteItem.packaging_unit)
                    this.types = this.piece_types
                }

                if (!type) {
                    return
                }

                this.deliveryNoteItem.packaging_unit = type

                factor = type.factor

                this.deliveryNoteItem.packaging = this.deliveryNoteItem.packaging / factor
            },
            async open(data) {
                if (this.selectedWarehouse) {
                    this.warehouseData = JSON.parse(JSON.stringify(this.selectedWarehouse))
                    await this.loadData()
                }

                if (!data) {
                    this.editing = false
                    this.num_of_decimals = 2
                    const rand = Math.random().toString(16).substring(2, 8)
                    this.selectedIngredient = null
                    this.deliveryNoteItem = {
                        id: rand,
                        packaging_unit: '',
                        packaging: '',
                        quantity: '',
                        price_per_unit: '',
                        discount: 0
                    }
                    this.deliveryNoteItemInternal = {
                        price_per_unit: 0,
                        price_per_unit_bruto: 0,
                        total_price_neto: 0,
                        total_price: 0,
                        discount: '',
                        quantity: '1'
                    }
                } else {
                    this.editing = true
                    
                    this.unique_id = data.unique_id
                    const editItem = JSON.parse(JSON.stringify(data))

                    const decimals1 = this.getNumOfDecimalPlaces(data.price)
                    const decimals2 = this.getNumOfDecimalPlaces(data.price_per_unit)
                    const decimals3 = this.getNumOfDecimalPlaces(data.price_with_discount)
                    const decimals4 = this.getNumOfDecimalPlaces(data.price_with_discount_and_vat)

                    const allDecimals = [decimals1, decimals2, decimals3, decimals4]
                    this.num_of_decimals = Math.max(...allDecimals)
                    this.calculateItem(editItem)
                    if (this.warehouses && this.warehouses.length > 0) {
                        this.warehouseData = this.warehouses.find(ele => ele.id === editItem.warehouse_id)
                    }
                }


                this.$refs.addProductToDeliveryNote.show()
                if (!this.editing) {
                    await this.$nextTick()
                    this.$refs.ingredient.open = true
                    await this.$nextTick()
                    document.getElementsByClassName('ingredient')[0].getElementsByClassName('vs__search')[0].focus()
                }
            },
            hide() {
                this.$refs.addProductToDeliveryNote.hide()
            },
            async loadData() {
                if (!this.warehouseData || !this.supplier) {
                    return
                }


                try {
                    const resp = await this.$http.get(`/api/client/v1/ingredients/warehouse/${ this.supplier.id}/${ this.warehouseData.id}`)
                    const resp1 = await this.$http.get('/api/client/v1/invoice_config/warehouse_stock')

                    this.rawIngredients = resp.data
                    this.warehouse_stock_type = resp1.data
                    this.ingredients =  this.rawIngredients.filter(ingredient => ingredient.disabled !== true)
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                }
            },
            calculateItem(data) {
                this.deliveryNoteItem = {
                    id: data.id,
                    packaging_unit: data.packaging_unit,
                    packaging: data.packaging,
                    quantity: data.quantity,
                    price_per_unit: Number(this.$numberRounding(data.price_per_unit)),
                    price: Number(this.$numberRounding(data.price)),
                    price_with_discount: Number(this.$numberRounding(data.price_with_discount)),
                    price_with_discount_and_vat: Number(this.$numberRounding(data.price_with_discount_and_vat)),
                    discount: Number(Number(Number(data.discount) / 100).toFixed(2))
                }

                this.selectedIngredient = this.rawIngredients.find(ele => ele.id === data.ingredient_id)
                this.findTypeOfIngredient()

                this.deliveryNoteItemInternal.price_per_unit = data.price_per_unit
                this.deliveryNoteItemInternal.discount = data.discount * 10000
                this.deliveryNoteItemInternal.quantity = data.quantity

                this.calcAllValuesInitial()
            }
        }
    }
</script>

<style scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.dark-layout .append-text {
    background-color: #575757 !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.light-layout .append-text {
    background-color: #efefef !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}
</style>

<style>
.unit-select .vs__selected-options {
    padding: unset !important;
}

</style>
