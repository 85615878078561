<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <b-link class="brand-logo">
                <b-img
                    :src="appLogoImageName"
                    alt="logo"
                    style="height: 80px; width: 150px;"
                />
            </b-link>

            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="8"
                class="d-none d-lg-flex align-items-center justify-content-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
                lg="4"
                class="auth-bg px-2 pb-lg-5 pt-2"
            >
                <div class="d-flex justify-content-end">
                    <language-dropdown />
                </div>
                <div class="d-flex align-items-center justify-content-center h-100">
                    <b-col
                        sm="8"
                        md="6"
                        lg="12"
                        class="px-xl-2 mx-auto"
                    >
                        <b-card-title
                            title-tag="h2"
                            class="font-weight-bold mb-1"
                        >
                            {{$t('imprion_welcome')}}! 👋
                        </b-card-title>

                        <!-- form -->
                        <validation-observer ref="loginValidation">
                            <b-form
                                class="auth-login-form mt-2"
                                @submit.prevent
                            >
                                <!-- name -->
                                <b-form-group
                                    :label="$t('form_data.person_name')"
                                    label-for="register-name"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="$t('form_data.person_name')"
                                        rules="required|min:2"
                                    >
                                        <b-form-input
                                            id="register-field5"
                                            v-model="userName"
                                            :state="errors.length > 0 ? false:null"
                                            name="register-name"
                                            placeholder="Janez"
                                            autocomplete="off"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <!-- email -->
                                <b-form-group
                                    :label="$t('form_data.email')"
                                    label-for="login-email"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="$t('form_data.email')"
                                        rules="required|email"
                                    >
                                        <b-form-input
                                            id="login-email"
                                            v-model="userEmail"
                                            :state="errors.length > 0 ? false:null"
                                            name="login-email"
                                            placeholder="john@example.com"
                                            disabled
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <!-- Password One -->
                                <b-form-group
                                    :label="$t('general.password')"
                                    label-for="register-password"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="$t('general.password')"
                                        rules="required"
                                        vid="register-password"
                                    >
                                        <b-form-input
                                            id="register-field1"
                                            v-model="userPassword"
                                            :state="errors.length > 0 ? false:null"
                                            name="register-password"
                                            placeholder="**************"
                                            type="password"
                                            autocomplete="off"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <!-- Password Two -->
                                <b-form-group
                                    :label="$t('password.confirm_password')"
                                    label-for="register-confirm-password"
                                >
                                    <validation-provider
                                        #default="{ errors }"
                                        :name="$t('password.confirm_password')"
                                        rules="required|min:4|confirmed:register-password"
                                    >
                                        <b-form-input
                                            id="register-confirm-field2"
                                            v-model="userPasswordConfirm"
                                            :state="errors.length > 0 ? false:null"
                                            name="register-confirm-password"
                                            placeholder="**************"
                                            type="password"
                                            autocomplete="new-password"

                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>

                                <div class="text-center px-3" style="font-size: 15px;">
                                    {{ $t('general.choose_notifications_to_receive') }} <b>{{client_name}}</b> .
                                </div>

                                <div class="ml- my-2 d-flex justify-content-center">
                                    <div>
                                        <div class="d-flex" style="margin-bottom: 5px;">
                                            <div class="d-flex" style="width: 200px;">
                                                <div>
                                                    <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="BookIcon"/>
                                                </div>
                                                {{$t('print.message.changes_backlog')}}
                                            </div>
                                            <b-form-checkbox v-model="emailNotifications.changelog" switch />
                                        </div>

                                        <div class="d-flex"  style="margin-bottom: 5px;">
                                            <div class="d-flex" style="width: 200px;">
                                                <div>
                                                    <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="ShieldIcon"/>
                                                </div>
                                                {{$t('print.message.warning')}}
                                            </div>
                                            <b-form-checkbox v-model="emailNotifications.warning" switch />
                                        </div>

                                        <div class="d-flex"  style="margin-bottom: 5px;">
                                            <div class="d-flex" style="width: 200px;">
                                                <div>
                                                    <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="MailIcon"/>
                                                </div>
                                                {{$t('print.message.message')}}
                                            </div>
                                            <b-form-checkbox v-model="emailNotifications.messages" switch />
                                        </div>

                                        <div class="d-flex">
                                            <div class="d-flex" style="width: 200px;">

                                                <div>
                                                    <feather-icon style="height: 19px; width: 19px; margin-right: 6px;" icon="DollarSignIcon"/>
                                                </div>
                                                {{$t('print.message.finances')}}
                                            </div>
                                            <b-form-checkbox v-model="emailNotifications.finances" switch />
                                        </div>
                                    </div>
                                </div>

                                <!-- submit buttons -->
                                <b-button
                                    type="submit"
                                    variant="primary"
                                    block
                                    :disabled="loading || registered"
                                    @click="validationForm"
                                >
                                    {{ $t('general.registration') }}
                                    <b-spinner v-if="loading" class="ml-1" style="height: 15px; width: 15px"></b-spinner>
                                </b-button>
                            </b-form>
                        </validation-observer>

                    </b-col>
                </div>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import {
        BRow, BCol, BLink, BFormGroup, BFormInput, BCardTitle, BImg, BForm, BButton, BFormCheckbox, BSpinner
    } from 'bootstrap-vue'
    import { required, email } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import store from '@/store/index'
    import { $themeConfig } from '@themeConfig'
    import LanguageDropdown from '@/components/LanguageDropdown.vue'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            LanguageDropdown,
            BSpinner,
            BFormCheckbox,
            BRow,
            BCol,
            BLink,
            BFormGroup,
            BFormInput,
            BCardTitle,
            BImg,
            BForm,
            BButton,
            ValidationProvider,
            ValidationObserver
        },
        mixins: [togglePasswordVisibility],
        data() {
            return {
                registered: false,
                loading: false,
                emailNotifications: {
                    changelog: false,
                    warning: true,
                    messages: false,
                    finances: true
                },
                client_name: '',
                userPassword: '',
                userPasswordConfirm: '',
                userName: '',
                userSurname: '',
                userEmail: '',
                registrationLink: '',
                rememberMe: false,
                sideImg: require('@/assets/images/pages/login-v2.svg'),
                // validation rulesimport store from '@/store/index'
                required,
                email
            }
        },
        computed: {
            isUserLoggedIn() {
                return this.$store.getters['user/loggedIn']
            },
            passwordToggleIcon() {
                return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
            },
            imgUrl() {
                if (store.state.appConfig.layout.skin === 'dark') {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                    return this.sideImg
                }
                return this.sideImg
            }
        },
        methods: {
            validationForm() {
                this.$refs.loginValidation.validate().then(() => {
                    this.register()
                })
            },
            async register() {

                const payload = {
                    email: this.userEmail,
                    password: this.userPassword,
                    name: this.userName,
                    email_notifications: this.emailNotifications
                }

                if (this.isUserLoggedIn) {
                    this.$store.dispatch('user/logout')
                }

                try {
                    this.loading = true
                    const res = await this.$http.post(`/api/client/v1/registration/register/${  this.registrationLink}`, payload)

                    this.$printSuccess(this.$t('print.success.registration'))
                    await this.$store.dispatch('user/setUserData', res.data)
                    this.registered = true
                    await this.$router.replace({name: 'login'})
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.general'))
                } finally {
                    this.$store.state.app.showLoader = false
                    this.loading  = false
                }
            }

        },
        setup() {
            // App Name
            const { appLogoImageName } = $themeConfig.app
            return {
                appLogoImageName
            }
        },
        async mounted() {
            this.registrationLink = this.$route.params.registration_link

            try {
                const rez = await this.$http.get(`/api/client/v1/registration/data/${  this.registrationLink}`)
                this.userEmail = rez.data.email
                this.client_name = rez.data.client_name
            } catch (error) {
                this.$printWarning('register.expired_link')
                this.$router.replace({name: 'login'})
            }
        }
    }
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
