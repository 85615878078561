<template>
    <b-modal size="lg"  ref="PaymentModal" :title="$t('reporting.issued_bills.editing_payment_methods')">
        <div class="paytab_custom " style="overflow-y: auto; height: 100%; max-height: 100%;">
            <div class="mt-2" style="padding-left: 0.8rem; padding-right: 0.8rem">
                <validation-observer ref="validationPaymentPrice" >
                    <validation-provider ref="validator-errors" #default="{ errors }" :name="$t('reporting.issued_bills.total_left_on_the_bill')"  :rules="`required|is_number|max_number:${$numberRounding(priceToPay)}|decimalCustom:2|mustBePositive:true`">
                        <b-form-group
                            :label="`${$t('reporting.issued_bills.total_left_on_the_bill')}: ${$numberRounding(priceToPay)} €`"
                            label-class="font-weight-bold"
                            label-size="lg"
                        >
                            <b-input-group append="€">
                                <b-form-input
                                    autofocus
                                    :title="$t('reporting.issued_bills.total_left_on_the_bill')"
                                    v-model="inputPrice"
                                    class="priceInput"
                                />
                            </b-input-group>
                            <small class="text-danger">{{errors[0]}}</small>
                        </b-form-group>
                    </validation-provider>
                </validation-observer>
            </div>
            <div class="px-2" style="overflow-y: auto; height: 100%; max-height: 330px;">
                <b-row cols="2" >
                    <b-col class="" style="padding-top: 0.7rem; padding-left: 0.35rem; padding-right: 0.35rem" v-for="item of paymentTypes" :key="'payment_type_'+item.id">
                        <b-button variant="primary" :disabled="priceToPay===0" class="buttonText p-0 m-0" block @click="validatePrice(item)">
                            <div class="py-1">
                                {{item.name}}
                            </div>
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </div>
        <hr v-if="newPayments && newPayments.length>0" style="border: gray solid 1px; width: 95%"/>
        <div class="px-1 paytab_custom" style="overflow-y: auto; height: 100%; max-height: 130px;">
            <div v-for="(payment,index) of newPayments" :key="index" class="payments">
                <b-row >
                    <b-col cols="8">
                        {{getPaymentName(payment)}}
                    </b-col>
                    <b-col class="d-flex justify-content-end align-items-center">
                        {{$numberRounding(payment.total_price)}}
                    </b-col>
                    <b-col cols="1" class="d-flex justify-content-end align-items-center">
                        <img alt="X icon." style="height: 13px; display: inline; cursor: pointer" :src="require('@/assets/images/pages/ExitIcon.svg')" @click="removePayment(payment)"/>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div class="mt-4 px-1 tips" v-if="clientData && clientData.tier !== Tiers.Essential && tipsConfigMain.enabled">
            <validation-observer ref="validationPaymentPriceTip" >
                <div>
                    <validation-provider ref="validator-errors-tip" #default="{ errors }" :name="$t('reporting.issued_bills.tip_amount')"  :rules="inputPriceTip && inputPriceTip.length >0 ? `required|is_number|decimalCustom:2|mustBePositive:true`:''">
                        <b-form-group
                            :label="$t('general.tip')"
                        >
                            <b-input-group append="€">
                                <b-form-input
                                    :disabled="tipsConfigMain.tip_included_as_product"
                                    :title="$t('reporting.issued_bills.tip_amount')"
                                    :placeholder="$t('reporting.issued_bills.tip_amount')"
                                    v-model="inputPriceTip"
                                    class="priceInput"
                                />
                            </b-input-group>
                            <small class="text-danger">{{errors[0]}}</small>
                        </b-form-group>
                    </validation-provider>
                </div>
                <div v-if="isTipMoreThanZero">
                    <validation-provider #default="{ errors }" :name="$t('reporting.issued_bills.tip_payment_type')"  :rules="'required'">
                        <b-form-group :label="$t('reporting.issued_bills.tip_payment_type')">
                            <v-select :name="$t('reporting.issued_bills.tip_payment_type')" appendToBody v-model="paymentTypeTip" :options="newPayments" label="name" />
                            <small class="text-danger">{{errors[0]}}</small>
                        </b-form-group>
                    </validation-provider>
                </div>
            </validation-observer>
        </div>
        <template #modal-footer>
            <div class="d-flex justify-content-between w-100">
                <b-button variant="danger" style="margin-left: 0.5rem" @click="$refs.PaymentModal.hide()">{{$t('general.cancel')}}</b-button>
                <b-button variant="success" style="margin-right: 0.5rem" :disabled="priceToPay !== 0" @click="updatePayments">{{$t('general.update')}}</b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    import {BModal, BButton, BRow, BCol, BFormInput, BFormGroup, BInputGroup} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, decimalCustom, minNumber, maxNumber, isNumber} from '@validations'
    import * as Sentry from '@sentry/vue'
    import vSelect from 'vue-select'
    import {Tiers} from '@/libs/enums/Tiers'

    export default {
        components: {BModal, BButton, BRow, BCol, BFormInput, BFormGroup, BInputGroup, ValidationProvider, ValidationObserver, vSelect},
        data() {
            return {
                is_loading:false,
                show_modal:false,
                paymentTypesAll: [],
                priceError:'',
                newPayments:[],
                inputPrice:'',
                inputPriceTip: '',
                paymentTypeTip: null,
                price:0,
                priceToPay:0,
                bill:null,
                required,
                decimalCustom,
                minNumber,
                maxNumber,
                isNumber,
                tipsConfig: {},
                tipsConfigMain: {}
            }
        },
        computed: {
            Tiers() {
                return Tiers
            },
            clientData() {
                return this.$store.getters['client/clientData']
            },
            isTipMoreThanZero() {
                return Number(this.inputPriceTip) > 0
            },
            paymentTypes() {

                if (!this.bill || !this.bill.payment_types || !this.bill.payment_types.length || !this.paymentTypesAll || !this.paymentTypesAll.length) {
                    return []
                }

                const currentRule = `${this.bill.payment_types[0].payment_type}_${this.bill.payment_types[0].tax_certification}_${this.bill.payment_types[0].custom_numbering_key}`
                const allowedPaymentTypes = []

                for (const type of this.paymentTypesAll) {
                    const rule = `${type.payment_type}_${type.tax_certification}_${type.custom_numbering_key}`

                    if (rule === currentRule && type.active) {
                        allowedPaymentTypes.push(type)
                    }
                }

                return allowedPaymentTypes
            }
        },
        methods:{
            setTipsConfig() {
                this.tipsConfigMain = {}
                this.tipsConfigMain = {tip_included_as_product: this.bill.tip_included_as_product}

                let tipFound = false
                this.bill.payment_types.forEach(pt => {
                    if (pt.hidden || pt.total_price < 0 || pt.tip_amount > 0) {
                        tipFound = true
                    }
                })

                this.tipsConfigMain.enabled = tipFound || this.tipsConfig.enabled
            },


            async loadTipsConfig() {

                if (this.clientData && this.clientData.tier === Tiers.Essential) return

                try {
                    this.is_loading = true

                    const response = await this.$http.get(`/api/client/v1/tips/${this.bill.cash_register.business_unit.id}`)

                    this.tipsConfig = response.data ?? []

                    this.setTipsConfig()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.is_loading = false
                }
            },
            async loadData() {
                try {
                    this.is_loading = true

                    const response = await this.$http.get('/api/client/v1/payment_types')

                    this.paymentTypesAll = response.data ?? []
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.is_loading = false
                }
            },
            async updatePayments() {
                try {

                    let valid = true

                    if (this.clientData && this.clientData.tier !== Tiers.Essential && this.tipsConfigMain.enabled) {
                        await this.$refs.validationPaymentPriceTip.validate().then((success) =>  {
                            if (!success) {
                                valid = false
                            }
                        })
                    }

                    if (!valid) {
                        return
                    }

                    if (this.priceToPay !== 0) {
                        return
                    }

                    this.is_loading = true

                    const payload = {
                        payments:this.newPayments,
                        payments_tip:this.paymentTypeTip,
                        tip_amount:Math.round(Number(this.inputPriceTip) * 1000000)
                    }

                    await this.$http.patch(`/api/client/v1/issued_bills/payments/${this.bill.id}`, payload)

                    this.$printSuccess(this.$t('print.success.payments_updated'))
                    this.$emit('payment-changed')
                    this.$refs.PaymentModal.hide()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.is_loading = false
                }
            },
            validatePrice(item) {
                if (Number(this.inputPrice) === 0) {
                    this.$printWarning(this.$t('print.warning.more_than_zero'))
                    return
                }

                this.$refs.validationPaymentPrice.reset()
                if (item.fixed_price_enabled) {
                    this.addNewPayment(item)
                } else {
                    this.$refs.validationPaymentPrice.validate().then(valid => {
                        if (valid) {
                            this.addNewPayment(item)
                        }
                    })
                }
            },
            async addNewPayment(paymentType) {
                if (paymentType.fixed_price_enabled) {
                    await this.addNewFixedPayment(paymentType)
                } else {
                    this.price = parseInt(Math.round(Number(this.inputPrice) * 1000000), 10)
                    const index = this.newPayments.findIndex(payment => payment.payment_type_id === paymentType.id)

                    if (index > -1) {
                        this.newPayments[index].total_price = this.newPayments[index].total_price + this.price
                    } else {
                        const payment = {
                            payment_type_id: paymentType.id,
                            name: paymentType.name,
                            total_price: this.price,
                            use_custom_numbering: paymentType.use_custom_numbering,
                            custom_numbering_key: paymentType.custom_numbering_key,
                            tax_certification:paymentType.tax_certification,
                            payment_type:paymentType.payment_type,
                            custom_data:paymentType.custom_data,
                            fixed_price_enabled: false,
                            fixed_price:0
                        }
                        this.newPayments.push(payment)
                    }

                    this.priceToPay = this.priceToPay - this.price
                    this.inputPrice = this.$numberRounding(this.priceToPay)
                }
            },
            addNewFixedPayment(paymentType) {
                if (this.priceToPay >= paymentType.fixed_price) {
                    const index = this.newPayments.findIndex(payment => payment.payment_type_id === paymentType.id)

                    if (index > -1) {
                        this.newPayments[index].total_price = this.newPayments[index].total_price + paymentType.fixed_price
                    } else {
                        const payment = {
                            payment_type_id: paymentType.id,
                            name: paymentType.name,
                            total_price: paymentType.fixed_price,
                            use_custom_numbering: paymentType.use_custom_numbering,
                            custom_numbering_key: paymentType.custom_numbering_key,
                            tax_certification:paymentType.tax_certification,
                            payment_type:paymentType.payment_type,
                            custom_data:paymentType.custom_data,
                            fixed_price_enabled: true,
                            fixed_price:paymentType.fixed_price
                        }
                        this.newPayments.push(payment)
                    }

                    this.priceToPay = this.priceToPay - paymentType.fixed_price
                    this.inputPrice = this.$numberRounding(this.priceToPay)
                } else {
                    this.$refs['validator-errors'].errors = [`${this.$t('general.payment')} ( ${paymentType.name} ) ${this.$t('general.fixed_price')} ${this.$numberRounding(paymentType.fixed_price)}€, ${this.$t('general.more_than_amount')} ${this.$numberRounding(this.priceToPay)}€.`]
                }
            },
            removePayment(paymentType) {
                this.price = this.priceToPay + paymentType.total_price
                this.priceToPay = this.price
                this.inputPrice = this.$numberRounding(this.priceToPay)

                this.newPayments = this.newPayments.filter(payment => payment.payment_type_id !== paymentType.payment_type_id)
            },
            async show(item) {
                this.newPayments = []
                this.bill = item
                this.inputPrice = this.$numberRounding(item.total_price)
                this.price = item.total_price
                this.priceToPay = Number(this.$numberRounding(item.total_price)) * 1000000
                await this.loadTipsConfig()
                await this.setTip()
                this.$refs.PaymentModal.show()
            },
            getPaymentName(paymentType) {
                if (paymentType.fixed_price_enabled) {
                    return `${paymentType.name} ( ${parseInt(paymentType.total_price / paymentType.fixed_price)}x ${this.$numberRounding(paymentType.fixed_price)}€ )`
                } else {
                    return `${paymentType.name}`
                }
            },
            setTip() {
                this.inputPriceTip = null
                let tipFound = false

                for (let i = 0; i < this.bill.payment_types.length; i++) {
                    if (this.bill.payment_types[i].tip_amount > 0) {
                        this.inputPriceTip = this.$numberRounding(this.bill.payment_types[i].tip_amount)
                        tipFound = true
                    }
                }

                if (!tipFound) {
                    for (let i = 0; i < this.bill.payment_types.length; i++) {
                        if (this.bill.payment_types[i].total_price > 0 && this.bill.payment_types[i].hidden) {
                            this.inputPriceTip = this.$numberRounding(this.bill.payment_types[i].total_price)
                        }
                    }
                }
            }
        },
        async mounted() {
            await this.loadData()
        },
        watch:{
            'priceToPay'(val) {
                if (val === 0) {
                    this.$refs.validationPaymentPrice.reset()
                }
            },
            'newPayments'() {
                if ((!this.newPayments && this.newPayments.length <= 0) || !this.paymentTypeTip) return
                const payment = this.newPayments.filter((item) => { if (item.payment_type_id === this.paymentTypeTip.id) return item })

                if (payment.length === 0) {
                    this.paymentTypeTip = null
                }
            }
        }
    }
</script>

<style scoped>

.buttonText {
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: 0.05em;
}

.priceInput{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    text-align: end;
}

.payments{
    padding: 0.5rem;
    border-radius: 5px;
    border: gray solid 1px;
    font-weight: bold;
    margin-bottom: 0.5rem
}

</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>